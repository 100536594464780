import { Component, ElementRef, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-search-selection-panel',
  templateUrl: './search-selection-panel.component.html',
  styleUrls: ['./search-selection-panel.component.css']
})
export class SearchSelectionPanelComponent implements AfterViewInit,OnInit {
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;

  @ViewChild('brandStoriyScrollA', { static: false }) brandStoriyScrollA: ElementRef;
  @ViewChild('sliderRefAll', { static: false }) sliderRefAll: ElementRef;


  // @ViewChild('sliderRefAll') sliderRefAll: ElementRef;

  searchTerm: string = '';
  seletctUser: string = ''
  userData: any = []
  filterUser: any = []

  imagesDummy = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
  ]
  images = [
    { img: 'assets/body.png', value: 'User' },
    { img: 'assets/brand_black.png', value: 'brand' },
    { img: 'assets/t_shirt_black.png', value: 'Garment' },
    { img: 'assets/head.png', value: 'avatar' },
    { img: 'assets/space.png', value: 'space' },
  ];
  itemWidth: number;

  constructor(private firestore: AngularFirestore,) {
    this.user.subscribe(userdetails => {
      this.userData = userdetails
    });


  }
  imagesValue = [
    { img: 'assets/profilepic.png', value: 'User-1' },
    { img: 'assets/profilepic.png', value: 'User-2' },
    { img: 'assets/profilepic.png', value: 'User-3' },
    { img: 'assets/profilepic.png', value: 'User-4' },
    { img: 'assets/profilepic.png', value: 'User-5' },
    { img: 'assets/profilepic.png', value: 'User-6' },
    { img: 'assets/profilepic.png', value: 'User-7' },
    { img: 'assets/profilepic.png', value: 'User-8' },
  ];

  selectedImage: any;
  showSearchContainer: boolean;
  selectedUserFromList: any;

  ngAfterViewInit() {
    let scrollLeft = this.sliderRefAll.nativeElement
    // this.inifiniteXScrolls(scrollLeft)
    // this.setupInfiniteScroll()
  }

  ngOnInit() {
  }

  private setupInfiniteScroll(): void {

    const slider = this.brandStoriyScrollA.nativeElement;
    const moveElement = () => {
      // Use requestAnimationFrame for smoother visual updates
      requestAnimationFrame(() => {
        const firstChild = slider.firstElementChild as HTMLElement;
        const lastChild = slider.lastElementChild as HTMLElement;
        // Calculate the buffer based on the child width
        const childWidth = firstChild.offsetWidth;
        const buffer = childWidth * 2; // Adjust the buffer size as needed
        if (slider.scrollLeft + buffer >= slider.scrollWidth - slider.offsetWidth) {
          // Move the first child to the end and adjust scrollLeft
          slider.appendChild(firstChild);
          slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
        } else if (slider.scrollLeft <= buffer) {
          // Move the last child to the beginning and adjust scrollLeft
          slider.insertBefore(lastChild, firstChild);
          slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
        }
      });
    };


    // Throttle scroll events
    let isScrolling;
    const onScroll = () => {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(moveElement, 0); // Increase the timeout to delay the element movement
    };
    // Add smooth scrolling CSS transition if not already present
    slider.style.transition = 'scroll-left 0.1s ease-out';
    slider.addEventListener('scroll', onScroll);

  }

  // Handle movement during touch

  clearSearch() {
    this.searchTerm = '';
    this.showSearchContainer = false
    this.selectedUserFromList = null
    this.filterUser = null

  }
  seletctOption(item) {
    this.selectedImage = item;
    this.seletctUser = item.value
  }

  clickSearch() {
    console.log("searchTerm", this.searchTerm)
    if (this.searchTerm.length < 3) {
      alert('Please enter more than three letters.');
      return; // Exit the method early if the condition is not met
    }
    if (!this.selectedImage) {
      alert('Please Select Option.');
      return; // Exit the method early if the condition is not met
    }

    if (this.selectedImage?.value == 'User') {
      this.searchUserList()
    }
    this.showSearchContainer = true
  }

  seletctUserFromList(item) {
    this.selectedUserFromList = item;
    this.seletctUser = item.value
  }



  searchUserList() {
    let users = this.userData.filter((u) => u?.DisplayName.toLowerCase().includes(this.searchTerm?.toLowerCase()));
    this.filterUser = users
    console.log("User", users)
  }





  inifiniteXScrolls(el) {
    let lastScrollX = 0;
    let overlapCount = 0;
    let clones = [];
  
    // Check if getClientRects() returns a non-empty array
    const clientRects = el.getClientRects();
    if (clientRects.length == 0) {
      return null;
    }
  
    const elWidth = clientRects[0].width;
  
    // Create clones to simulate the infinite scroll effect
    while (el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= elWidth) {
      const clone = el.children[overlapCount].cloneNode(true);
      clones.push(clone);
      el.appendChild(clone);
      overlapCount++;
    }
  
    el.scrollTo({ left: lastScrollX });
    overlapCount -= 1;
    let lastScroll = el.scrollLeft = 10;
  
    function scrollHandler() {
      const maxScroll = el.scrollWidth - el.clientWidth;
      const overlapOverflow =
        (el.children[overlapCount].offsetLeft - el.offsetLeft) -
        elWidth +
        el.children[overlapCount].getClientRects()[0].width;
  
      // Scrolling to the right
      if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
        el.scrollLeft = overlapOverflow;
      }
      // Scrolling to the left
      else if (el.scrollLeft <= 0 && el.scrollLeft < lastScroll) {
        el.scrollLeft = maxScroll - overlapOverflow;
      }
  
      lastScroll = el.scrollLeft;
    }
  
    el.addEventListener('scroll', scrollHandler);
  
    // Cleanup function to remove clones and event listeners
    return function cleanup() {
      const scroll = el.scrollLeft;
      clones.forEach((clone) => clone.replaceWith(""));
      el.scrollTo({ left: scroll });
      el.removeEventListener('scroll', scrollHandler);
    };
  }
  
}
