import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren, Renderer2, OnInit, OnDestroy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { Snapshot } from 'src/app/models/snapshot';
import { CommonService } from 'src/app/services/common.service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { PinPublishViewerDilog } from '../templets/pin-publish-popup/pin-publish';
import { MatDialog } from '@angular/material/dialog';
import { SocialMediaShareDialog } from 'src/app/public/news/news.component';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { debounce } from 'lodash'; // Install lodash or use a similar utility



interface PerformanceMemory {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
}

@Component({
  selector: 'app-drips',
  templateUrl: './drips.component.html',
  styleUrls: ['./drips.component.css'],
})
export class DripsComponent {
  @Input() userSnapShotsInput: any;
  @Input() mainMenuFlag: boolean;
  @Input() menuFlag: any;
  @Input() StoryIds: any;
  @Input() showpanel: boolean;
  @Input() nextPage: boolean;
  @Input() snapInitCall: boolean;
  @Input() teamplateFlag: any;
  @Input() FunctionFlag: any;
  @Input() evntempFile: any;
  @Input() storyCardflag: any;
  @Input() storyText: any;
  @Input() envpageFlage: any;

  
  
  

  

  

  



  private subscriptions: Subscription = new Subscription();
  private imageLoadCompleted = false; // Flag to track if image has loaded
  private pendingIndex: number | null = null; // Store index to process after image loads
  private debouncedLoadMore = debounce(() => this.moreButtonForNext(), 300); // Adjust debounce time as needed


  @Output() avatrVrmValueEmitter: EventEmitter<any> = new EventEmitter();
  @Output() avatrNewVrmValueEmitter: EventEmitter<any> = new EventEmitter();
  @Output() garmentDataEmitter = new EventEmitter<any>();
  @Output() dataEmitter: EventEmitter<any> = new EventEmitter();
  @Output() branEmitter: EventEmitter<any> = new EventEmitter();
  @Output() brandSnapShottEmmit: EventEmitter<any> = new EventEmitter();
  @Output() receveidPanelEven: EventEmitter<any> = new EventEmitter();
  @Output() environmentDataEmitter = new EventEmitter<any>();
  @Output() userDataEmitter = new EventEmitter<any>();
  @Output() visibleIndex = new EventEmitter<any>();
  @Output() emmitStoryIndex = new EventEmitter<any>();

  @Output() emmitGarmentNavParameter = new EventEmitter<any>();
  @Output() emmitSearch = new EventEmitter<any>();

  




  isLoadingMore: boolean = false; // Track if new snapshots are being loaded
  loadMoreTimeout: any; // Track timeout for checking if more snapshots should be loaded
  showManualLoadButton: boolean = false;


  initialMemoryUsage: PerformanceMemory | null;
  finalMemoryUsage: PerformanceMemory | null;
  private renderer: Renderer2
  selectedgender: string;
  tab: string;
  selecteGarmentId: any;
  typeOfGarment: any;
  garmentTypeID: any;
  brandboardCollection: any = [];
  loading: boolean;
  displayProgressSpinner: boolean;
  storyPinterestPinsList: any = [];
  pinImg: any;
  newBrandObject: any = [];
  branUrl: any;
  flagForShowLottieTemplet: boolean = false;
  lottieURL: any;
  horizonatlVisibleIndex: number;
  snapSingleUrl: any;
  functionflagValue: string;
  flagForShowThreeJsInDripsTemplet: boolean = false;
  flagForShowThreeJs: boolean;
  passId: any;
  dataBlobUrl: any;
  storyID: any;
  avatraUrls: any;
  footwear: any;
  headgear: any;
  vrmAvtarUrl: any;
  genders: any;
  garmentbottom: any;
  garmenturl: any;
  envUrl: any;
  isCheckedFocus: boolean = true;
  errorMessage: string;
  evnLink: any;
  garmentLink: any;
  GarmentsGender: any;
  avtarUrl: string;
  isChecked: boolean;
  threejsFlag: boolean = false;
  WinSizeCall: boolean = true;
  loadedCompoent: string;
  modelflag: boolean;
  storyFlag: boolean;
  selectedAnimation: string;
  animationCollection: any = [];
  snapshotUrl: any;
  flag_v2: boolean = true;
  showloadhingFlage: boolean = false;
  activeIndex: number = 0;
  selectedSnapShot: any;
  selectedGarmentName: string;
  brandUrl: string;
  realType: any;
  newbrandUrl: string;
  selectedUserName: string;
  selectedAvatarName: any;
  brandData: any = [];
  brandDataCache = {};
  brandDataCacheOrder = [];
  garmentData: any = [];
  garmentDataCache = {};
  garmentDataCacheOrder = [];

  UserData = []
  userDataCache = {};
  userDataCacheOrder = [];

  AvatarData = []
  avatarDataCacheOrder = [];
  avatarDataCache = {};

  environmentDataCache = [];
  environmentDataCacheOrder = [];
  maxCacheSize: number = 5;
  environmentData: any = [];
  endIdx: number;
  storyId: any;
  pageFlagRout: string;
  loadPanelLoader: boolean = false;
  flagStory: boolean = true;
  animationListShow: boolean =false;

  windowWidth: number;
  windowHeight: number;
  isMobile: boolean;
  dummytext: any;


  clickOnHeart($event: MouseEvent) {
    throw new Error('Method not implemented.');
  }

  // Laztkiadubg view child compoment
  @ViewChild('scrollableDiv', { read: ElementRef }) public scrollableDiv: ElementRef<any>;
  @ViewChildren('horizontalScrollContainer') horizontalScrollContainers: QueryList<ElementRef>;
  @ViewChildren('upperwearCard') upperwearCard: QueryList<ElementRef>;




  // CONSTANT DEFINATION   
  imageWidth = 345; // Set the desired image width
  imageHeight = 345; // Set the desired image height
  lastCalledIndex = -1;


  // Load all environemnt to display the enviment name, small dataset   
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<any[]>;

  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;

  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;

  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;

  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;

  animationList = this.firestore
    .collection('Animations')
    .valueChanges({ idField: 'id' }) as Observable<any[]>;


  pinterestBoardPinsStatus = this.firestore
    .collection('pinterest-board-pins-status')
    .valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;


  // input object 



  // Snashots object 
  snapshots: any = [];
  paginatedSnapshots: any = [];
  paginationObject = { pageSize: 12, pageNumber: 1 };
  closeDiv: boolean;
  wholeDiv: boolean = false



  stroyLodaingFlag: boolean;
  successMsgs: string;
  alertFlag: boolean;
  waitingForMoreSnap: boolean;
  next: number = 1;
  evnFileList: any = [];
  isLoading: boolean;


  UpperwearID: string = 'UpperwearID';
  BottomwearID: string = 'BottomwearID';
  HeadwearID: string = 'HeadwearID';
  FootwearID: string = 'FootwearID';

  dummyFlag: boolean = true;
  mostVisibleIndex: any = 0;
  garmentsCollection: any = [];
  brandsCollection: any = [];
  thumbnilflag: boolean
    ;
  currentImages: string[] = [];


  //for horizontal scrolling
  isHorizontalScrolling: boolean;
  scrollTimeout: NodeJS.Timeout;
  previousScrollPositions: number[] = [];
  garmentPanelFlag: boolean = false;
  logInFlag: boolean;
  sideNavHeight: number;
  brandStoryScrollerHeight: number;
  snapshot: any;
  userCollapseFlag: boolean;
  userData: any = [];
  avatarCollapseFlag: boolean;
  evenCollapseFlag: boolean;
  germentCollapseFlag: boolean;
  evnFileListFileName: any;
  urlEvn: any;
  gltfImgSrc: string;
  environmentFileName: any;
  twoDprivew: string;
  skyBoXall: string;
  evsname: any;
  evsdiplaynameALL: any;
  hideFlag: boolean;
  brandCollapseFlag: any;
  clothDetails: any;
  previousSnapShotsLength: number = 0;
  previousNextPage: any; // Variable to store the previous value of nextPage


  brandheartFlag: boolean;
  selectedBrandId: any;
  userSnapShotList: any = [];
  brandLogourl: any;
  brandObject: any;
  pinterstJsonMap = {
    StoryID: undefined,
    upperwear: undefined,
    footwear: undefined,
    headwear: undefined,
  };

  constructor(public dialog: MatDialog, private cdr: ChangeDetectorRef, private storage: AngularFireStorage, private scrollService: CommonService,
    private pinterestAPI: PinterestService, private commonService: CommonService, private firestore: AngularFirestore, private http: HttpClient, public router: Router,
    private activatedRoute: ActivatedRoute
  ) {


    this.logInFlag = this.commonService.isLoggedIn();



    // intialization for snashots dataset 
    if (!this.mainMenuFlag) {
      this.subscriptions.add(
        this.commonService.getSnapshots().subscribe(async (snapshots: any) => {
          if (snapshots?.object && snapshots?.object.length > 0) {
            if (!this.mainMenuFlag) {
              this.snapshots = snapshots?.object
              
              // this.snapshots.forEach(async (brand) => {
              //   let type = 'b';
              //   let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
              //   brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
              // });
              // this.snapshots.forEach((snapshot) => {
              //   if (snapshot.avatarName === undefined) {
              //     snapshot.avatarName = this.getAvatartDetails(snapshot);
              //   } else {
              //     // You can save or log the avatarName here
              //     console.log(`Avatar Name: ${snapshot.avatarName}`);
              //   }
              // });
              this.paginatedSnapshots = this.snapshots.slice(0, 12);
              console.log("this.paginatedSnapshots",this.paginatedSnapshots)
              const perf: any = performance;
              if (perf.memory) {
                const memoryInfo = perf.memory;
                const jsHeapSizeLimitMB = (memoryInfo.jsHeapSizeLimit / (1024 * 1024)).toFixed(2);
                const totalJSHeapSizeMB = (memoryInfo.totalJSHeapSize / (1024 * 1024)).toFixed(2);
                const usedJSHeapSizeMB = (memoryInfo.usedJSHeapSize / (1024 * 1024)).toFixed(2);


              } else {
                console.log('performance.memory is not supported in this browser.');
              }

            }
          }
          this.WinSizeCall = false;
        })
      );
    }
    this.subscriptions.add(
      this.environmentCollection.subscribe((evnFile) => {
        this.evnFileList = evnFile;
      })
    );

    this.subscriptions.add(
      this.garmentsList.subscribe((garments) => {
        this.garmentsCollection = garments;
      })
    );

    this.subscriptions.add(
      this.brandsFilesList.subscribe((brands) => {
        this.brandsCollection = brands;
      })
    );

    this.subscriptions.add(
      this.brandBoardList.subscribe((brandboard) => {
        this.brandboardCollection = brandboard;
      })
    );

    this.subscriptions.add(
      this.animationList.subscribe((animation) => {
        this.animationCollection = animation;
      })
    );

    this.subscriptions.add(
      this.pinterestBoardPinsStatus.subscribe((response) => {
        this.storyPinterestPinsList = response;
      })
    );

    this.subscriptions.add(
      this.user.subscribe((user) => {
        this.userData = user;
      })
    );
  }


  ngOnChanges(changes: SimpleChanges) {

    if (changes['mainMenuFlag'] && this.mainMenuFlag) {
      setTimeout(async () => {
        await this.processSnapshots();
        // this.cdr.detectChanges();
      }, 5000);
    }

    if (changes['snapInitCall']) {
      setTimeout(async () => {
        await this.processSnapshots();
        // this.cdr.detectChanges();
      }, 500);
    }

    
    if (changes['storyCardflag']) {
      this.WinSizeCall =  this.storyCardflag
    }

    if (changes['storyText']) {
      this.dummytext =  this.storyText
    }

    if (changes['FunctionFlag']) {
      this.functionflagValue = null
      setTimeout(async () => {
        this.envUrl = this.evntempFile
        this.functionflagValue = this.FunctionFlag;
        console.log('evNurl',   this.envUrl);
      }, 50);

      setTimeout(async () => {
        // this.WinSizeCall = true
        console.log('evNurl',   this.envUrl);
        this.functionflagValue = 'windowExpand';
      }, 100);
    }
    

    if (changes['nextPage'] && changes['nextPage'].currentValue == true) {
      setTimeout(async () => {
        await this.processSnapshots();
        this.cdr.detectChanges();
      }, 5000);
    }



    if (changes['StoryIds'] && changes['StoryIds']['currentValue'] != '' && changes['StoryIds']['currentValue'] != undefined) {
      this.storyId = changes['StoryIds']['currentValue'];
      setTimeout(() => {
        // Stop the scroll or execute any logic after 5 seconds
        this.scrollToId(this.storyId)
      }, 50);
    }

    if (changes['teamplateFlag'] && changes['teamplateFlag'].currentValue == true) {
      setTimeout(async () => {
        await this.processSnapshots();
        this.cdr.detectChanges();
      }, 500);
    }



  }



  processSnapshots() {
    this.user.subscribe((user) => {
      if (user) {
        const userData = user;
        this.paginatedSnapshots = [...this.userSnapShotsInput]; // Spread operator to ensure a new array
        this.paginatedSnapshots.forEach((snapshot) => {
          const userObject = userData.find((user) => user.UserID === snapshot.UserID);
          if (userObject) {
            snapshot.DisplayName = userObject.DisplayName;
          } else {
          }
        });
        console.log("this.paginatedSnapshots", this.paginatedSnapshots);
        if (this.storyId != undefined) {
          console.log("selected story Id", this.storyId);
          setTimeout(() => {
            // Stop the scroll or execute any logic after 5 seconds
            if (this.flagStory) {
              this.scrollToId(this.storyId)
              this.flagStory = false
            }
          }, 0);
        }
      } else {
        console.error('No user data received');
      }
    }, error => {
      console.error('Error fetching user data:', error);
    });
  }



  ngOnInit() {

    this.checkCurrentRoute();
    this.updateWindowDimensions()

    setTimeout(() => {
      this.functionflagValue = 'windowExpand';
    }, 1000);






    this.initialMemoryUsage = this.getMemoryUsage();
    if (this.initialMemoryUsage) {
      const memoryUsageMB = this.initialMemoryUsage.usedJSHeapSize / (1024 * 1024);
      console.log('Initial Memory Usage:', memoryUsageMB.toFixed(2), 'MB');
    } else {
      console.log('Initial Memory Usage: null');
    }

    this.renderer?.listen('document', 'click', this.onDocumentClick.bind(this));
    this.setBrandStoryScrollerHeight()

  }

  ngAfterViewInit() {
    this.finalMemoryUsage = this.getMemoryUsage();
    if (this.finalMemoryUsage) {
      const finalMemoryUsageMB = this.finalMemoryUsage.usedJSHeapSize / (1024 * 1024);
      console.log('Final Memory Usage:', finalMemoryUsageMB.toFixed(2), 'MB');
    } else {
      console.log('Final Memory Usage: null');
    }
    // Calculate memory difference
    this.horizontalScrollContainers.forEach((container, index) => {
      container.nativeElement.addEventListener('scroll', (event) => this.onHorizontalScroll(event, index));
    });
    this.scrollableDiv.nativeElement.addEventListener('scroll', this.onVerticalScroll.bind(this));

  }

  scrollToId(id: string) {
    if (id != '') this.scrollService.scrollToElementById(id);
  }



  getMemoryUsage(): PerformanceMemory | null {
    if (window.performance && (window.performance as any).memory) {
      return (window.performance as any).memory;
    } else {
      console.warn('Memory API not supported in this browser');
      return null;
    }
  }

  ////Scrolling part start....

  onVerticalScroll() {
    this.findVisibleElementIndex()
    this.isHorizontalScrolling = false;
  }

  // findVisibleElementIndex() {
  //   let scrollContainer = this.scrollableDiv.nativeElement;
  //   const scrollTop = scrollContainer.scrollTop;
  //   const containerHeight = scrollContainer.clientHeight;
  //   let mostVisibleIndex = -1;
  //   let highestVisibility = 0;
  //   this.paginatedSnapshots.forEach((snapshot, index) => {
  //     const element = document.getElementById('element' + index);
  //     if (element) {
  //       const elementTop = element.offsetTop;
  //       const elementHeight = element.offsetHeight;
  //       // Calculate the visible part of the element
  //       const visibleTop = Math.max(elementTop, scrollTop);
  //       const visibleBottom = Math.min(
  //         elementTop + elementHeight,
  //         scrollTop + containerHeight
  //       );
  //       const visibleHeight = Math.max(0, visibleBottom - visibleTop);

  //       // Calculate visibility percentage
  //       const visibility = visibleHeight / elementHeight;

  //       if (visibility > highestVisibility) {
  //         highestVisibility = visibility;
  //         mostVisibleIndex = index;
  //       }
  //     }
  //   });
  //   // Call moreButtonForNext at specific indices (6, 12, 18, ...)
  //   this.mostVisibleIndex = mostVisibleIndex
  //   console.log("Story Index", this.mostVisibleIndex)

  //   if (
  //     mostVisibleIndex > 0 &&
  //     mostVisibleIndex % 18 === 0 &&
  //     mostVisibleIndex !== this.lastCalledIndex
  //   ) {
  //     this.moreButtonForNext()
  //     if (mostVisibleIndex !== -1) {
  //       // this.outStoryIndeX.emit(mostVisibleIndex);
  //     }
  //     this.lastCalledIndex = mostVisibleIndex; // Update the last called index
  //   }
  // }

  findVisibleElementIndex() {
    let scrollContainer = this.scrollableDiv.nativeElement;
    const scrollTop = scrollContainer.scrollTop;
    const containerHeight = scrollContainer.clientHeight;
    let mostVisibleIndex = -1;
    let highestVisibility = 0;

    this.paginatedSnapshots.forEach((snapshot, index) => {
      const element = document.getElementById('element' + index);
      if (element) {
        const elementTop = element.offsetTop;
        const elementHeight = element.offsetHeight;
        const visibleTop = Math.max(elementTop, scrollTop);
        const visibleBottom = Math.min(
          elementTop + elementHeight,
          scrollTop + containerHeight
        );
        const visibleHeight = Math.max(0, visibleBottom - visibleTop);
        const visibility = visibleHeight / elementHeight;

        if (visibility > highestVisibility) {
          highestVisibility = visibility;
          mostVisibleIndex = index;
        }
      }
    });
    this.mostVisibleIndex = mostVisibleIndex
    this.visibleIndex.emit(this.mostVisibleIndex)
    // console.log("Most Visible Index:", mostVisibleIndex);
    const areVariablesEqual = this.paginatedSnapshots.length === this.endIdx && mostVisibleIndex + 1 === this.endIdx;
    // console.log("areVariablesEqual", areVariablesEqual, this.paginatedSnapshots.length, this.endIdx, mostVisibleIndex + 1)
    if (areVariablesEqual) {
      this.showManualLoadButton = true
    }

    const itemsPerPage = 6;

    const shouldLoadMore = mostVisibleIndex >= (this.lastCalledIndex + itemsPerPage) && (mostVisibleIndex % itemsPerPage === 0);
    this.emmitStoryIndex.emit(shouldLoadMore)
    if (shouldLoadMore && mostVisibleIndex !== this.lastCalledIndex) {
      if (!this.mainMenuFlag) {
        this.moreButtonForNext();
      }
      console.log("Should Load More:", shouldLoadMore, "Last Called Index:", this.lastCalledIndex);

      this.lastCalledIndex = mostVisibleIndex;
    }
  }






  ///scrolling part end....




  ////Data push part is start..

  // moreButtonForNext() {
  //   const itemsPerPage = 12;
  //   this.next += 1;
  //   this.waitingForMoreSnap = true;
  //   const startIdx = (this.next - 1) * itemsPerPage;
  //   const endIdx = this.next * itemsPerPage;
  //   const newSnapshots = this.snapshots.slice(startIdx, endIdx);
  //   if (newSnapshots.length > 0) {
  //     this.paginatedSnapshots.push(...newSnapshots);
  //     this.stroyLodaingFlag = false;
  //     this.alertFlag = true;
  //     this.successMsgs = 'Story loaded successfully';
  //     this.waitingForMoreSnap = false;
  //     console.log("startIndex", startIdx);
  //     console.log("endIndex", endIdx);
  //     console.log("Story Message", this.successMsgs);
  //     console.log("Story Length", this.paginatedSnapshots.length);
  //     const perf: any = performance;
  //     if (perf.memory) {
  //       const memoryInfo = perf.memory;
  //       const jsHeapSizeLimitMB = (memoryInfo.jsHeapSizeLimit / (1024 * 1024)).toFixed(2);
  //       const totalJSHeapSizeMB = (memoryInfo.totalJSHeapSize / (1024 * 1024)).toFixed(2);
  //       const usedJSHeapSizeMB = (memoryInfo.usedJSHeapSize / (1024 * 1024)).toFixed(2);

  //       console.log('JS Heap Size Limit:', jsHeapSizeLimitMB, 'MB');
  //       console.log('Total JS Heap Size:', totalJSHeapSizeMB, 'MB');
  //       console.log('Used JS Heap Size:', usedJSHeapSizeMB, 'MB');
  //     } else {
  //       console.log('performance.memory is not supported in this browser.');
  //     }

  //   } else {
  //     this.stroyLodaingFlag = true;
  //     this.successMsgs = 'No more stories available';
  //     this.alertFlag = true;
  //     setTimeout(() => {
  //       this.alertFlag = false;
  //     }, 2000);
  //   }
  // }


  moreButtonForNext() {
    if (this.isLoadingMore) {
      return; // Prevent multiple simultaneous loads
    }
    if (this.pageFlagRout != 'drip') {
      return
    }
    this.isLoadingMore = true;
    this.waitingForMoreSnap = true;
    const itemsPerPage = 12;
    this.next += 1;
    const startIdx = (this.next - 1) * itemsPerPage;
    const endIdx = this.next * itemsPerPage;
    // if(endIdx ==  this.snapshots.length ){
    //   this.showManualLoadButton = false; // Hide the manual load button
    //   return
    // }
    const newSnapshots = this.snapshots.slice(startIdx, endIdx);
    this.endIdx = endIdx
    if (newSnapshots.length > 0) {
      this.paginatedSnapshots.push(...newSnapshots);
      this.stroyLodaingFlag = false;
      this.alertFlag = true;
      this.successMsgs = 'Story loaded successfully';
      this.showManualLoadButton = false; // Hide the manual load button
    } else {
      this.stroyLodaingFlag = true;
      this.successMsgs = 'No more stories available';
      this.alertFlag = true;
      // Show the manual load button
    }



    console.log("newSnapshots", newSnapshots)
    console.log("startIdx", startIdx)
    console.log("endIdx", endIdx)
    console.log("snapshots", this.snapshots.length)
    console.log("this.paginatedSnapshots", this.paginatedSnapshots.length)

    this.isLoadingMore = false;
    this.waitingForMoreSnap = false;
    this.checkForStuckLoading(); // Start the check for stuck loading
  }

  // Method to manually trigger load more if stuck
  checkForStuckLoading() {
    if (this.loadMoreTimeout) {
      clearTimeout(this.loadMoreTimeout); // Clear existing timeout
    }

    this.loadMoreTimeout = setTimeout(() => {
      if (this.isLoadingMore) {
        this.moreButtonForNext(); // Retry loading more snapshots
      }
    }, 5000); // Retry after 5 seconds if loading is stuck
  }

  onImageLoad() {
    this.imageLoadCompleted = true;
    // If there is a pending request to load more items, process it
    if (this.pendingIndex !== null) {
      this.moreButtonForNext();
      this.pendingIndex = null; // Clear pending index after processing
    }
  }

  ////Data push part is start..




  //User Display Name
  getDisplayName(snapshot: any): string {
    return snapshot.DisplayName ?? snapshot.profileName ?? (snapshot.profileName === '' ? 'user-name' : snapshot.UserID);
  }

  //Showing Date
  convertFirestoreTimestampToDate(timestamp: any): Date {
    return new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
    );
  }

  //envirnment Name
  getEnvironmentName(environment: string): string {
    if (!this.evnFileList) return 'default';
    const env = this.evnFileList.find(snap => snap.storageURL === `Environments/${environment}`);
    return env?.displayName ?? 'default';
  }
  //Find Real Type
  getGarmentrealtype(garmentId: string): string {
    return this.garmentsCollection.find((b: any) => b.id === garmentId)?.RealType ?? '';
  }

  //showing Brandlogo With Garment Id
  getBrandImageByGarmetnID(garmentId: string): string {
    const imgURL = encodeURI(this.getBrandLogoURL(garmentId)).replace('/', '%2F');
    // const newPath = imgURL.replace('Brands%2F', 'Brands%2Fcompressed');
    const newPath = imgURL.replace('Brands%2F', 'Brands%2Fcompressed%2F');
    return `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${newPath}?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c`;
  }

  //For Finding Brand Logo BrandLogoUrl 
  getBrandLogoURL(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const brandId = garment?.BrandID;
    const brand = this.brandsCollection.find((b: any) => b.id === brandId);
    return brand?.LogoURL ?? '';
  }

  //Cloth Or Garment Name 
  getGarmentName(garmentId: string): string {
    const garment = this.garmentsCollection.find((b: any) => b.id === garmentId);
    return garment?.Name ?? '';
  }


  // for fetching the avatar Name
  async getAvatartDetails(snapShots) {
    try {
      snapShots.avatarName = '';
      const documents = await this.commonService.getUserAvatarDetails(snapShots.UserID, snapShots.AvatarID);
      if (documents?.length > 0) {
        const firstDocument = documents[0];
        snapShots.avatarName = firstDocument.AvatarName || snapShots.AvatarID;
      } else {
        snapShots.avatarName = snapShots.AvatarID;
      }
    } catch (error) {
      console.error('Error in component:', error);
      throw error; // You can choose to re-throw the error or handle it here
    }
  }

  // Converting image Temperory function
  compressImages(): void {
    this.isLoading = true;
    const promises = this.paginatedSnapshots.map(snapshot => {
      return this.compressImageFromUrl(snapshot.SnapshotFileUrl, 64, 64).then(compressedImage => {
        snapshot.compressedUrl = compressedImage;
      });
    });
    Promise.all(promises).then(() => {
      this.isLoading = false;
    }).catch(error => {
      console.error('Error compressing images:', error);
      this.isLoading = false;
    });
  }

  compressImageFromUrl(src: string, maxWidth: number, maxHeight: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Important for cross-origin images
      img.src = src;
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL('image/jpeg', 0.7)); // 0.7 is the compression quality (0 to 1)
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  }

  //Temporary function for uploading thumbnail
  async updateThumbNail(snap, i) {
    this.thumbnilflag = true;
    const functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressImageAndUpdateUrl";
    try {
      const response = await this.http.post(functionUrl, { storyId: snap.id, snapshotFileUrl: snap.SnapshotFileUrl }).toPromise();
      this.thumbnilflag = false;
      alert("thumbnail updated");
      snap.SnapshotFileUrlMini = response['SnapshotFileUrlMini'];
      snap.SnapshotFileUrlMicro = response[' SnapshotFileUrlMicro'];
      this.paginatedSnapshots[i] = snap;

      return response;
    } catch (error) {
      this.thumbnilflag = false;
      alert("Failed to update thumbnail");
      return error;
    }
  }

  //slider horizontal scroll
  onHorizontalScroll(event: Event, index: number): void {
    const target = event.target as HTMLElement;
    const currentScrollLeft = target.scrollLeft;
    if (currentScrollLeft !== this.previousScrollPositions[index]) {
      if (index === this.mostVisibleIndex) {
        this.isHorizontalScrolling = true;
      }
      this.previousScrollPositions[index] = currentScrollLeft;
    }
    this.horizonatlVisibleIndex = Math.round(currentScrollLeft / this.getElementWidth());
  }



  async openGarmentPanelByCard(data) {
    await this.openGarmentNav(this, data.key, data.garmentId, data.type, event)
  }


  //for open the Garment Panel.
  // async openGarmentNav(snapshot, typerId, garmentId, type,  event) {
  //   event.stopPropagation();
  //   this.germentCollapseFlag = false;
  //   setTimeout(() => {
  //     this.germentCollapseFlag = true;
  //   }, 0);
  //   // Set flags to false first
  //   this.closeDiv = false;
  //   this.wholeDiv = false;
  //   this.avatarCollapseFlag = false;
  //   this.evenCollapseFlag = false;
  //   this.brandCollapseFlag = false;
  //   this.userCollapseFlag = false;
  //   // Then set flags to true
  //   this.closeDiv = true;
  //   // Set other data
  //   this.selecteGarmentId = garmentId;
  //   this.typeOfGarment = type;
  //   this.garmentTypeID = typerId;

  //   this.garmentData = await this.garmentPanelDataFunction(garmentId,typerId);

  //   this.selectedGarmentName = this.getGarmentName(garmentId);
  //   this.brandUrl = this.getBrandImageByGarmetnID(garmentId);
  //   this.realType = this.getGarmentrealtype(garmentId);
  //   console.log("this.selecteGarmentId", this.selecteGarmentId,this.typeOfGarment,this.garmentTypeID,
  //     this.selectedGarmentName, this.brandUrl , this.realType)
  // }


  // async openUserPanel(snapshot, event) {
  //   event.stopPropagation();
  //   this.snapshot = snapshot
  //   this.closeDiv = true
  //   this.wholeDiv = false;
  //   this.userCollapseFlag = true
  //   this.garmentPanelFlag = false
  //   this.avatarCollapseFlag = false
  //   this.evenCollapseFlag = false;
  //   this.brandCollapseFlag = false;
  //   this.germentCollapseFlag = false
  //   this.selectedUserName = this.getDisplayName(snapshot)
  //   this.UserData = await this.userPanelDataFunction(snapshot.UserID)
  // }


  // openAvatarPanel(snapshot, event) {
  //   event.stopPropagation();
  //   this.snapshot = snapshot
  //   this.closeDiv = true
  //   this.wholeDiv = false;
  //   this.avatarCollapseFlag = true
  //   this.userCollapseFlag = false
  //   this.garmentPanelFlag = false
  //   this.evenCollapseFlag = false;
  //   this.brandCollapseFlag = false;
  //   this.germentCollapseFlag = false
  //   this.selectedUserName = this.getDisplayName(snapshot)
  //   this.selectedAvatarName = snapshot.avatarName

  // }


  roughSizeOfObject(object: any) {
    let objectList = [];
    let stack = [object];
    let bytes = 0;

    while (stack.length) {
      let value = stack.pop();

      if (typeof value === 'boolean') {
        bytes += 4;
      } else if (typeof value === 'string') {
        bytes += value.length * 2;
      } else if (typeof value === 'number') {
        bytes += 8;
      } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
        objectList.push(value);

        for (let i in value) {
          stack.push(value[i]);
        }
      }
    }
    return bytes;
  }

  bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
  }

  async openBrandPanel(garmentId: any, selection: any, event: any) {
    this.wholeDiv = false;
    event.stopPropagation();
    if (this.pageFlagRout == 'threads') {
      this.loadPanelLoader = true
    }
    this.brandCollapseFlag = true;
    this.userCollapseFlag = false;
    this.avatarCollapseFlag = false;
    this.evenCollapseFlag = false;
    this.garmentPanelFlag = false;
    this.germentCollapseFlag = false;
    this.closeDiv = true;
    this.brandheartFlag = true;
    this.newbrandUrl = this.getBrandImageByGarmetnID(garmentId);
    console.log("this.brandUrl", this.newbrandUrl);
    this.brandData = [];

    // Find the selectedBrandId based on garmentId
    let garmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    this.selectedBrandId = garmentObject.BrandID;

    // Check if brandData for this selectedBrandId is already cached
    if (this.brandDataCache[this.selectedBrandId]) {
      this.brandData = this.brandDataCache[this.selectedBrandId];
      console.log("when already cache", this.brandData);
      // Reorder the cache to keep the latest accessed item at the end
      const index = this.brandDataCacheOrder.indexOf(this.selectedBrandId);
      if (index !== -1) {
        this.brandDataCacheOrder.splice(index, 1); // Remove existing entry
      }
      this.brandDataCacheOrder.push(this.selectedBrandId); // Add at the end to maintain order
    } else {
      // Fetch brandData if not already cached
      this.brandData = await this.brandPanelDataFunction(this.selectedBrandId);
      // Cache the fetched brandData
      if (this.brandDataCacheOrder.length >= 5) {
        const oldestCacheId = this.brandDataCacheOrder.shift(); // Remove the oldest cached entry
        delete this.brandDataCache[oldestCacheId];
      }
      this.brandDataCache[this.selectedBrandId] = this.brandData;
      this.brandDataCacheOrder.push(this.selectedBrandId); // Add at the end to maintain order

      console.log("this.brandDataCache", this.brandDataCache[this.selectedBrandId]);
      console.log("this.brandDataCache", this.brandDataCache);

    }

    const cacheSizeInBytes = this.roughSizeOfObject(this.brandDataCache);
    console.log("Current cache size: ", this.bytesToSize(cacheSizeInBytes));
    console.log("Cache order: ", this.brandDataCacheOrder);
    this.branEmitter.emit(this.brandData)
    this.loadPanelLoader = false

    this.getBrnadStoryImage(this.selectedBrandId);
    this.getBrandData(garmentId);
    // this.emitDataToParent()
    // Adjust sidebar width
    const sidebarWidth = window.innerWidth > 768 ? '249px' : '181px';
    const sidebar = document.getElementById('mySidenav');
    if (sidebar) {
      sidebar.style.width = sidebarWidth;
    }

  }

  // async openGarmentNav(snapshot: any, typerId: any, garmentId: any, type: any, event: any) {
  //   event.stopPropagation();
  //   this.germentCollapseFlag = true;
  //   this.closeDiv = false;
  //   this.wholeDiv = false;
  //   this.avatarCollapseFlag = false;
  //   this.evenCollapseFlag = false;
  //   this.brandCollapseFlag = false;
  //   this.userCollapseFlag = false;
  //   this.closeDiv = true;
  //   this.selecteGarmentId = garmentId;
  //   this.typeOfGarment = type;
  //   this.garmentTypeID = typerId;
  //   this.garmentData = [];
  //   this.selectedGarmentName = this.getGarmentName(garmentId);
  //   this.brandUrl = this.getBrandImageByGarmetnID(garmentId);
  //   this.realType = this.getGarmentrealtype(garmentId);

  //   if (this.garmentDataCache[garmentId]) {
  //     this.garmentData = this.garmentDataCache[garmentId];

  //     console.log("when already cache ", this.garmentData);
  //     const index = this.garmentDataCacheOrder.indexOf(garmentId);
  //     if (index !== -1) {
  //       this.garmentDataCacheOrder.splice(index, 1); // Remove existing entry
  //     }
  //     this.garmentDataCacheOrder.push(garmentId); // Add at the end to maintain order
  //   } else {
  //     this.garmentData = await this.garmentPanelDataFunction(garmentId, typerId);
  //     if (this.garmentDataCacheOrder.length >= 5) {
  //       const oldestCacheId = this.garmentDataCacheOrder.shift(); // Remove the oldest cached entry
  //       delete this.garmentDataCache[oldestCacheId];
  //     }
  //     this.garmentDataCache[garmentId] = this.garmentData;
  //     this.garmentDataCacheOrder.push(garmentId); // Add at the end to maintain order
  //     let filePathNewTemp =  this.garmentData.userFiles.find((file) => file.link ==  this.selecteGarmentId  && file.fileType == 'Garment360Cache');
  //     console.log("filePathNewTemp..........",filePathNewTemp)

  //     console.log("filePathNewTemp..........",filePathNewTemp.filepath)
  //     const storageRef = this.storage.ref(filePathNewTemp.filepath);
  //     storageRef.getDownloadURL().subscribe({
  //         next: (downloadUrl) => {
  //             console.log("Download URL:", downloadUrl);
  //         },
  //         error: async (error) => {
  //             if (error.code === 'storage/object-not-found') {
  //               console.error("File not found:",filePathNewTemp.filepath);
  //               await this.fileUrlUpadate(this.clothDetails);
  //                 console.error("File not found:",filePathNewTemp.filepath);

  //             } else {
  //                 console.error("Error fetching download URL:", error);
  //             }
  //         }
  //     });

  //     console.log("this.garmentDataCache", this.garmentDataCache[garmentId]);
  //     console.log("this.garmentDataCache", this.garmentDataCache);
  //   }

  //   const cacheSizeInBytes = this.roughSizeOfObject(this.garmentDataCache);
  //   console.log("Current cache size: ", this.bytesToSize(cacheSizeInBytes));
  //   console.log("Cache order: ", this.garmentDataCacheOrder);

  //   console.log("this.selecteGarmentId", this.selecteGarmentId, this.typeOfGarment, this.garmentTypeID,
  //     this.selectedGarmentName, this.brandUrl, this.realType);

  //     this.emitGarmentDataToParent()
  // }


  
  async openGarmentNav(snapshot: any, typerId: any, garmentId: any, type: any, event: any) {
    if (this.pageFlagRout == 'threads') {
      this.loadPanelLoader = true
    }


    event.stopPropagation();
    this.showTempPanel()
    this.germentCollapseFlag = false;
    setTimeout(() => {
      this.germentCollapseFlag = true;
    }, 0);

    this.closeDiv = true;
    this.wholeDiv = false;
    if (this.pageFlagRout == 'edit-news') {
      setTimeout(() => {
        this.germentCollapseFlag = false;
        this.closeDiv = false
        this.emmitGarmentNavParameter.emit({
          snapshot: snapshot,
          typerId: typerId,
          garmentId: garmentId,
          type: type,
          event: event
        });
      }, 0);
      return
    }
  
    this.avatarCollapseFlag = false;
    this.evenCollapseFlag = false;
    this.brandCollapseFlag = false;
    this.userCollapseFlag = false;
    this.closeDiv = true;
    this.selecteGarmentId = garmentId;
    this.typeOfGarment = type;
    this.garmentTypeID = typerId;
    this.garmentData = [];
    this.selectedGarmentName = this.getGarmentName(garmentId);
    this.brandUrl = this.getBrandImageByGarmetnID(garmentId);
    this.realType = this.getGarmentrealtype(garmentId);

    if (this.garmentDataCache[garmentId]) {
      this.garmentData = this.garmentDataCache[garmentId];
      console.log("when already cache ", this.garmentData);
      const index = this.garmentDataCacheOrder.indexOf(garmentId);
      if (index !== -1) {
        this.garmentDataCacheOrder.splice(index, 1); // Remove existing entry
      }
      this.garmentDataCacheOrder.push(garmentId); // Add at the end to maintain order
    } else {
      this.garmentData = await this.garmentPanelDataFunction(garmentId, typerId);
      if (this.garmentDataCacheOrder.length >= 5) {
        const oldestCacheId = this.garmentDataCacheOrder.shift(); // Remove the oldest cached entry
        delete this.garmentDataCache[oldestCacheId];
      }
      this.garmentDataCache[garmentId] = this.garmentData;
      this.garmentDataCacheOrder.push(garmentId); // Add at the end to maintain order
      let filePathNewTemp = this.garmentData.userFiles.find((file) => file.link == this.selecteGarmentId && file.fileType == 'Garment360Cache');
      console.log("filePathNewTemp.....................................................filePathNewTemp", filePathNewTemp)

      this.clothDetails = this.garmentData.garment
      if (!filePathNewTemp) {
        console.error("filePathNewTemp is undefined or null");
        await this.fileUrlUpadate(this.clothDetails);
      } else {
        if (filePathNewTemp.filepath) {
          console.log("filePathNewTemp.filepath:", filePathNewTemp.filepath);
          const storageRef = this.storage.ref(filePathNewTemp.filepath);
          try {
            const downloadUrl = await storageRef.getDownloadURL().toPromise();
            console.log("Download URL:", downloadUrl);
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              console.error("File not found:", filePathNewTemp.filepath);
              await this.fileUrlUpadate(this.clothDetails);
            } else {
              console.error("Error fetching download URL:", error);
            }
          }
        } else {
          console.error("filePathNewTemp.filepath is undefined");
          await this.fileUrlUpadate(this.clothDetails);
        }
      }

      console.log("this.garmentDataCache", this.garmentDataCache[garmentId]);
      console.log("this.garmentDataCache", this.garmentDataCache);
    }

    const cacheSizeInBytes = this.roughSizeOfObject(this.garmentDataCache);
    console.log("Current cache size: ", this.bytesToSize(cacheSizeInBytes));
    console.log("Cache order: ", this.garmentDataCacheOrder);

    console.log("this.selecteGarmentId", this.selecteGarmentId, this.typeOfGarment, this.garmentTypeID,
      this.selectedGarmentName, this.brandUrl, this.realType);
    if (this.mainMenuFlag) {
      this.emitGarmentDataToParent();
      this.loadPanelLoader = false
    }

    if (this.pageFlagRout == 'edit-news') {
      this.emitGarmentDataToParent();
    }
  }



  async openUserPanel(snapshot: any, event: any) {
    event.stopPropagation();
    this.showTempPanel()
    if (this.pageFlagRout == 'threads') {
      this.loadPanelLoader = true
    }
    this.snapshot = snapshot;
    this.closeDiv = true;
    this.wholeDiv = false;
    this.userCollapseFlag = true;
    this.garmentPanelFlag = false;
    this.avatarCollapseFlag = false;
    this.evenCollapseFlag = false;
    this.brandCollapseFlag = false;
    this.germentCollapseFlag = false;
    this.selectedUserName = this.getDisplayName(snapshot);
    const userId = snapshot.UserID;
    this.UserData = []
    if (this.userDataCache[userId]) {
      this.UserData = this.userDataCache[userId];
      console.log("User data from cache", this.UserData);
      const index = this.userDataCacheOrder.indexOf(userId);
      if (index !== -1) {
        this.userDataCacheOrder.splice(index, 1); // Remove existing entry
      }
      this.userDataCacheOrder.push(userId); // Add at the end to maintain order
    } else {
      this.UserData = await this.userPanelDataFunction(userId);
      if (this.userDataCacheOrder.length >= 5) {
        const oldestCacheId = this.userDataCacheOrder.shift(); // Remove the oldest cached entry
        delete this.userDataCache[oldestCacheId];
      }
      this.userDataCache[userId] = this.UserData;
      this.userDataCacheOrder.push(userId); // Add at the end to maintain order

      console.log("User data fetched and cached", this.UserData);
    }
    this.emitUserDataToParent()
    this.loadPanelLoader = false

    const cacheSizeInBytes = this.roughSizeOfObject(this.userDataCache);
    console.log("Current cache size: ", this.bytesToSize(cacheSizeInBytes));
    console.log("Cache order: ", this.userDataCacheOrder);
  }

  async openAvatarPanel(snapshot: any, event: any) {
    event.stopPropagation();
    this.showTempPanel()
    if (this.pageFlagRout == 'threads') {
      this.loadPanelLoader = true
    }

    this.snapshot = snapshot;
    this.closeDiv = true;
    this.wholeDiv = false;
    this.avatarCollapseFlag = true;
    this.userCollapseFlag = false;
    this.garmentPanelFlag = false;
    this.evenCollapseFlag = false;
    this.brandCollapseFlag = false;
    this.germentCollapseFlag = false;
    this.selectedUserName = this.getDisplayName(snapshot);
    this.selectedAvatarName = snapshot.avatarName;
    this.AvatarData = []
    const avatarId = snapshot.AvatarID; // Assuming avatarID is available in snapshot
    let userId = snapshot.UserID

    if (this.avatarDataCache[avatarId]) {
      this.AvatarData = this.avatarDataCache[avatarId];
      console.log("Avatar data from cache", this.AvatarData);
      const index = this.avatarDataCacheOrder.indexOf(avatarId);
      if (index !== -1) {
        this.avatarDataCacheOrder.splice(index, 1); // Remove existing entry
      }
      this.avatarDataCacheOrder.push(avatarId); // Add at the end to maintain order
    } else {
      this.AvatarData = await this.avatarPanelDataFunction(userId, avatarId);
      if (this.avatarDataCacheOrder.length >= 5) {
        const oldestCacheId = this.avatarDataCacheOrder.shift(); // Remove the oldest cached entry
        delete this.avatarDataCache[oldestCacheId];
      }
      this.avatarDataCache[avatarId] = this.AvatarData;
      this.avatarDataCacheOrder.push(avatarId); // Add at the end to maintain order

      console.log("Avatar data fetched and cached", this.AvatarData);
      this.emitDataToParent()
      this.loadPanelLoader = false

    }

    const cacheSizeInBytes = this.roughSizeOfObject(this.avatarDataCache);
    console.log("Current cache size: ", this.bytesToSize(cacheSizeInBytes));
    console.log("Cache order: ", this.avatarDataCacheOrder);
  }

  async environmentPanel(environment: any, event: any) {
    event.stopPropagation();
   
    if (this.mainMenuFlag) {
      this.loadPanelLoader = true
    }
    this.evenCollapseFlag = true;
    this.brandCollapseFlag = false;
    this.userCollapseFlag = false;
    this.garmentPanelFlag = false;
    this.avatarCollapseFlag = false;
    this.germentCollapseFlag = false;
    this.closeDiv = true;
    this.wholeDiv = false;

    const evenListName = 'Environments/';
    const environmentKey = evenListName + environment;
    this.environmentData = []
    // Check if the environment data is in the cache
    if (this.environmentDataCache[environmentKey]) {
      // Data is cached
      this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL === environmentKey);
      this.urlEvn = environment;
      this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945';
      this.environmentFileName = environment.Environment;
      this.environmentData = this.environmentDataCache[environmentKey];
      console.log("Environment data from cache", this.environmentDataCache[environmentKey]);
    } else {
      // Data is not cached, fetch from server
      this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL === environmentKey);
      this.evnFileListFileName['envFileName'] = environment;

      this.environmentData = await this.environmentPanelDataFunction(environment); // Fetch environment data

      // Cache the fetched data
      if (this.environmentDataCacheOrder.length >= this.maxCacheSize) {
        const oldestCacheKey = this.environmentDataCacheOrder.shift(); // Remove the oldest cached entry
        delete this.environmentDataCache[oldestCacheKey];
      }

      this.environmentDataCache[environmentKey] = this.environmentData;
      this.environmentDataCacheOrder.push(environmentKey); // Add new entry at the end

      this.urlEvn = environment;
      this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945';
      this.environmentFileName = environment.Environment;

      console.log("Environment data fetched and cached", this.environmentDataCache[environmentKey]);
    }

    // Open the model with the environment data
    this.openModelWithEnvironment(environmentKey, this.environmentFileName);
    this.showTempPanel()

    this.emitEnvironmentDataToParent()

    this.loadPanelLoader = false
    const cacheSizeInBytes = this.roughSizeOfObject(this.environmentDataCache);
    console.log("Current cache size: ", this.bytesToSize(cacheSizeInBytes));
    console.log("Cache order: ", this.environmentDataCacheOrder);
  }

  private openModelWithEnvironment(environmentKey: string, environmentFileName: any) {
    this.showTempPanel()
    if (this.envpageFlage) {
      this.evenCollapseFlag = false
      this.closeDiv = false
      return
    }

    const evnEncodedUrl = encodeURI(environmentKey).replace('/', '%2F');
    const evnUrl = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + evnEncodedUrl + '?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf';
    this.twoDprivew = evnUrl;
    this.skyBoXall = this.twoDprivew;
    this.evsname = environmentFileName;
    this.evsdiplaynameALL = this.evnFileListFileName['displayName'];
    this.hideFlag = true;
  }


  // environmentPanel(environment, event) {
  //   event.stopPropagation();
  //   this.evenCollapseFlag = true;
  //   this.brandCollapseFlag = false;
  //   this.userCollapseFlag = false;
  //   this.garmentPanelFlag = false;
  //   this.avatarCollapseFlag = false;
  //   this.userCollapseFlag = false
  //   this.germentCollapseFlag = false
  //   this.closeDiv = true;
  //   this.wholeDiv = false;
  //   let evenListName = 'Environments/';
  //   this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment);
  //   this.evnFileListFileName['envFileName'] = environment;
  //   let evnUrl = evenListName + environment;
  //   this.urlEvn = environment;
  //   this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945';
  //   this.environmentFileName = environment.Environment;
  //   this.openModelevn(evnUrl, this.environmentFileName);
  // }

  closeByClickOnCOmpnent() {
    this.wholeDiv = true
    this.closeDiv = false
  }

  setBrandStoryScrollerHeight() {
    this.sideNavHeight = window.innerHeight
    this.brandStoryScrollerHeight = window.innerHeight - 270;
  }





  // async openBrandPanel(garmentId: any, selection: any, event) {
  //   if (this.mainMenuFlag == undefined) {
  //     this.wholeDiv = false
  //     event.stopPropagation();
  //     //panale opning flag condition
  //     this.brandCollapseFlag = true;
  //     this.userCollapseFlag = false
  //     this.avatarCollapseFlag = false;
  //     this.evenCollapseFlag = false;
  //     this.garmentPanelFlag = false;
  //     this.germentCollapseFlag = false
  //     this.closeDiv = true;
  //     this.brandheartFlag = true;
  //     this.newbrandUrl = this.getBrandImageByGarmetnID(garmentId)
  //     console.log("this.brandUrl", this.newbrandUrl)
  //     let garmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
  //     this.selectedBrandId = garmentObject.BrandID;
  //     this.brandData = await this.brandPanelDataFunction(this.selectedBrandId);
  //     this.getBrnadStoryImage(this.selectedBrandId);
  //     this.getBrandData(garmentId);
  //     const sidebarWidth = window.innerWidth > 768 ? '249px' : '181px'; // 768px is a common breakpoint for desktop sizes
  //     const sidebar = document.getElementById('mySidenav');
  //     if (sidebar) {
  //       sidebar.style.width = sidebarWidth;
  //     }
  //   } else if (this.mainMenuFlag) {
  //     let garmentObject = this.garmentsCollection.find(
  //       (b: any) => b.id === garmentId
  //     );
  //     this.selectedBrandId = garmentObject.BrandID;
  //   }
  // }

  // Define a cache object and an array to track the order of cached entries




  getBrandData(garmentId: any) {
    let garmentObject = this.garmentsCollection.find(
      (b: any) => b.id === garmentId
    );
    this.brandObject = this.brandsCollection.find(
      (b: any) => b.id == garmentObject.BrandID
    );
    this.brandLogourl = this.getBrandImage(this.brandObject.LogoURL);
  }

  getBrnadStoryImage(BrandId: any) {
    // let reqInputObject = { id: BrandId, pageNumber: 1 };
    // this.commonService
    //   .yologramSnapshotByBrands(reqInputObject)
    //   .subscribe(async (response: any) => {
    //     this.userSnapShotList = response.object;
    //   });
  }
  navigateOnThread() {
    if (this.brandCollapseFlag) {
      this.router.navigate([
        '/threads',
        { brandId: this.selectedBrandId, M: 'S', Ex: 'N' },
      ]);
    } else {
      let gender =
        this.clothDetails.Gender == 'F' ? 'W' : this.clothDetails.Gender;
      this.router.navigate([
        '/threads',
        { brandId: this.selectedBrandId, M: gender, Ex: 'N' },
      ]);
    }

  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      brandEncodedurl +
      '?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c';
    return url;
  }

  shareBrand(brandId: any, event: any) {
    if (this.tab == 'story') {
      this.selectedgender = 'S';
    }
    if (this.tab == 'mens-wear') {
      this.selectedgender = 'M';

    }
    if (this.tab == 'womens-wear') {
      this.selectedgender = 'W';

    }
    // } else {
    //   this.selectedgender =
    //     this.brandObject.Gender == 'F' ? 'W' : this.brandObject.Gender;
    // }

    event.stopPropagation





    let link =
      'https://yolomove-social-live.web.app/threads;brandId=' +
      brandId +
      ';M=' +
      this.selectedgender +
      ';Ex=Y';
    // let text = 'Check out '+ this.brandNameSelected +' on Yologram '
    // console.log(text)
    if (navigator.share) {
      navigator
        .share({
          title: 'Share Brand',
          text: 'Check out ' + this.brandObject.BrandName + ' on Yologram ',
          url: link,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  setTab(selectedtab: any, gender) {
    this.tab = selectedtab;

    // this.genderselet = gender

    // if( this.tab=='story'){
    //   this.router.navigate(['/threads', { 'brandId': this.selectedBrandId, 'M': 'S', 'Ex': 'N' }])
    // }
    // let gen = gender == 'F' ? 'W' : gender;
    this.router.navigate([
      '/threads',
      { brandId: this.selectedBrandId, M: gender, Ex: 'N' },
    ]);
  }


  showPintrestIcon(clothId: any) {
    let flag = false;
    let grandmentObject = this.garmentsCollection.find(
      (b: any) => b.id === clothId
    );
    let brandBoardObject;
    if (grandmentObject != undefined && grandmentObject.BrandID != undefined) {
      brandBoardObject = this.brandboardCollection.find(
        (b: any) => b.brandId == grandmentObject.BrandID
      );
      //let show = './assets/Pinterest-logo.png'
    }
    if (brandBoardObject != undefined) {
      flag = true;
    }
    return flag;
  }

  showSharedStory(
    garmentId: any,
    type: any,
    snapshotFileUrl: any,
    storyID: any
  ): boolean {
    let brandId: any;
    let boardId: any;

    if (this.garmentsCollection.length > 0) {
      let garmentObject = this.garmentsCollection.find(
        (b: any) => b.id === garmentId
      );
      if (garmentObject && garmentObject['Name'] != undefined) {
        brandId = garmentObject['BrandID'];
        let brandBoardObject = this.brandboardCollection.find(
          (b: any) => b.brandId == brandId
        );
        boardId =
          brandBoardObject && brandBoardObject['boardId'] != undefined
            ? brandBoardObject['boardId']
            : undefined;
      }
    }

    let storyObject = this.storyPinterestPinsList.find(
      (b: any) => b.StoryID == storyID
    );

    // Check if the story is already shared
    if (storyObject && storyObject[type] != undefined) {
      return true;
    }

    return false;
  }



  shareOnPinterest(
    garmentId: any,
    type: any,
    snapshotFileUrl: any,
    storyID: any
  ) {
    setTimeout(() => {
      this.loading = false;
    }, 9000);
    this.loading = true;
    let brandId: any;
    let boardId: any;
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find(
        (b: any) => b.id === garmentId
      );
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        brandId = grandmentObject['BrandID'];
        let brandBoardObject = this.brandboardCollection.find(
          (b: any) => b.brandId == brandId
        );
        boardId =
          brandBoardObject && brandBoardObject['boardId'] != undefined
            ? brandBoardObject['boardId']
            : undefined;
      }
    }
    if (boardId == undefined || brandId == '') {
      this.loading = false;
      alert('This brand is not linked with Pinterest board.');
      return true;
    }

    let jsonObject = {
      avatarURL: snapshotFileUrl,
      boardId: boardId,
    };
    let jsonInput = this.pinterstJsonMap;
    let storyObject = this.storyPinterestPinsList.find(
      (b: any) => b.StoryID == storyID
    );

    if (storyObject && storyObject[type] != undefined) {
      let jsonInpt = { pinId: storyObject[type] };
      this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
        this.displayProgressSpinner = false;
        this.pinImg = data.media.images['400x300'].url;
        this.openModleForPublishedPin();
        this.loading = false;
      });
      return true;
    }

    this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {
      if (storyObject == undefined) {
        let pinsMappingObject: any = {
          StoryID: storyID,
        };
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id;
        if (type == 'footwear') pinsMappingObject['footwear'] = result.id;
        if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id;
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id;
        this.firestore
          .collection('pinterest-board-pins-status')
          .add(pinsMappingObject);
      } else if (storyObject && storyObject[type] == undefined) {
        if (type == 'headwear') storyObject['headwear'] = result.id;
        if (type == 'footwear') storyObject['footwear'] = result.id;
        if (type == 'upperwear') storyObject['upperwear'] = result.id;
        if (type == 'headwear') storyObject['headwear'] = result.id;
        this.firestore
          .collection('pinterest-board-pins-status')
          .doc(storyObject['id'])
          .update(storyObject);
      }

      this.loading = false;
      alert('Pin published successfully.');
      return true;
    });
    return false;
  }

  openModleForPublishedPin() {
    this.loading = false;
    const dialogRef = this.dialog.open(PinPublishViewerDilog);
    dialogRef.componentInstance.pinImgSrc = this.pinImg;
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = false;
    });
  }

  showLottieFiles(url, snapUrl) {
    this.lottieURL = ""
    this.snapSingleUrl = ""
    this.lottieURL = url
    this.snapSingleUrl = snapUrl
    setTimeout(() => {
      this.flagForShowLottieTemplet = true
    }, 400)
  }

  closeLottieTemplate() {
    this.flagForShowLottieTemplet = false
  }


  openShareModal(snapshot) {
    let grandmentObject = this.garmentsCollection.find(
      (b: any) => b.id === snapshot.UpperwearID
    );
    const dialogRef = this.dialog.open(SocialMediaShareDialog);
    dialogRef.componentInstance.storyId = snapshot;
    dialogRef.componentInstance.garmentDetails = grandmentObject.Name;
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = false;
      this.loading = false;
    });
  }





  getElementHeight(): number {
    // Return the height of one vertical item
    return this.scrollableDiv.nativeElement.offsetHeight / this.paginatedSnapshots.length;
  }

  getElementWidth(): number {
    // Return the width of one horizontal item
    return this.scrollableDiv.nativeElement.querySelector('.horizontal-scroll-container').offsetWidth;
  }

  getHorizontalItems(snapshot: any): any[] {
    // Return an array representing the horizontal items in the snapshot
    snapshot.home = "Home"
    return [snapshot.home, snapshot.HeadwearID, snapshot.UpperwearID, snapshot.BottomwearID, snapshot.FootwearID].filter(item => item);
  }

  async openNews3DPassValue(envUrl, AvatarUrl, snap, upperwear, bottomWear, footWear, headwear, event) {
    event.stopPropagation();
    this.showloadhingFlage = true
    this.WinSizeCall = true
    let dataUrl = await this.convertImageToBase64(snap.SnapshotFileUrl);
    if (dataUrl != undefined) {
      this.showloadhingFlage = false
    }
    this.threejsFlag = true
    this.errorMessage = '';
    this.isCheckedFocus = false;
    this.dataBlobUrl = "";
    let Garments = await this.garmentsCollection.find(
      (gaementList) =>
        gaementList.id ===
        (snap.HeadwearID ||
          snap.UpperwearID ||
          snap.BottomwearID ||
          snap.FootwearID)
    );
    this.envUrl = envUrl;
    this.avatraUrls = AvatarUrl;
    this.garmenturl = upperwear;
    this.garmentbottom = bottomWear;
    this.vrmAvtarUrl = snap.vrmURL;
    this.genders = Garments.Gender;
    this.headgear = headwear;
    this.footwear = footWear;
    this.storyID = snap.StoryID;
    this.dataBlobUrl = dataUrl;
    this.snapshot = snap;
    this.passId = snap.id;
    this.flagForShowThreeJs = true;
    this.flagForShowThreeJsInDripsTemplet = true;
    console.log("this.genders", this.genders)
    // this.functionflagValue = 'windowActualSize';
    this.functionflagValue = 'LoadAvatar' + '_' + this.avatraUrls + '_StoryID' + this.storyID;
    this.emitAvatarNewsData()
    // this.avatrNewValueEmitter.emit(this.functionflagValue)
  }

  async convertImageToBase64(url: string): Promise<string> {
    return await new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'Anonymous'; // Handle CORS
      image.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        let dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
        canvas = null;
      };
      image.onerror = reject;
      image.src = url;
    });
  }


  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsCollection.find(
      (garmentList) => garmentList.id === text
    );
    if (Garments) {
      let brandId = Garments.BrandID;
      let gender = Garments.Gender;
      this.GarmentsGender = Garments.Gender;
      let garmentType = Garments.GarmentType;
      let zipfilename = Garments.id;
      // alert(zipfilename)
      let packlocation =
        'AvatarBlenderClothes%2F' +
        brandId +
        '_' +
        gender +
        '%2F' +
        garmentType +
        '%2F' +
        zipfilename;
      this.garmentLink =
        'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
        packlocation +
        '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
      return this.garmentLink;
    } else {
      // Handle the case when Garments is not found
      // console.error('Garments not found for id: ' + text);
      return null; // or return an appropriate value or throw an error
    }
  }

  copyTextToClipEven(snap) {
    let evnFileName = snap;
    if (evnFileName != 'default') {
      this.evnLink =
        'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2F' +
        evnFileName +
        '?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf';
    } else if (evnFileName == 'default') {
      this.evnLink = evnFileName;
    }
    return this.evnLink;
  }

  copyTextToClipAvtar(user, avatar) {
    this.avtarUrl =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderSaves%2F' +
      user +
      '%2F' +
      avatar +
      '?alt=media&token=20b1dc1e-681b-4d96-a342-7a4407fab4e0&_gl=1*10jumsf*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NzUyMjU5NC4xNzAuMS4xNjk3NTIyNjE1LjM5LjAuMA..';
    return this.avtarUrl;
  }


  closeThreeDBUTTON() {
    this.errorMessage = '';
    // this.threeDopen = !this.threeDopen
    this.flagForShowThreeJsInDripsTemplet = !this.flagForShowThreeJsInDripsTemplet;
    // this.functionflagValue = 'windowActualSize';
    this.WinSizeCall = false;
    this.isCheckedFocus = false;
    this.loadedCompoent = 'loaded';
    setTimeout(() => {
      // this.functionflagValue = '';
    }, 100)
    this.modelflag = false;
    // this.functionflagValue = 'distroyRenderdData';


  }
  onSelectAnimation(data) {
    this.modelflag = true;
    this.storyFlag = false;
    let url = data;
    let animationEncodedUrl = encodeURI(url).replace(/\//g, '%2F');
    let storageUrl =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      animationEncodedUrl +
      '?alt=media&token=c6ec5912-f818-429b-81a3-d44b8f92657c';
    this.selectedAnimation = storageUrl;
    this.functionflagValue = 'bvhAnimationUrl' + '_' + this.selectedAnimation;
    this.animationListShow = false
  }



  toggleSwitch() {
    this.modelflag = false;
    this.storyFlag = true
    this.isChecked = !this.isChecked;
    this.switchFlag();
  }

  switchFlag() {
    if (this.isChecked) {
      this.functionflagValue = 'TogglePostCardOn';
    } else {
      this.functionflagValue = 'TogglePostCardOff';
    }
  }

  switchFlagforFocus() {
    // this.isCheckedFocus = !this.isCheckedFocus;
    if (this.isCheckedFocus) {
      this.functionflagValue = 'FocusOnAvatar';
      this.isCheckedFocus = false
    } else if (!this.isCheckedFocus){
      this.functionflagValue = 'FocusOnStory';
      this.isCheckedFocus = true
    }
  }

  setAvtarNotFoundMessege(event) {
    alert("Avtar not found please try again !!!")
    this.closeThreeDBUTTON()
  }

  setAvatarAndClothLoaded($event) {
    this.loadedCompoent = $event;
    if (this.loadedCompoent == "loaded") {
      // this.functionflagValue = 'FocusOnStory';
      this.functionflagValue = 'windowExpand';
      this.WinSizeCall = true
      // this.switchFlagforFocus()
      // setTimeout(() => {
      //   this.functionflagValue = 'windowActualSize';
      //   this.WinSizeCall = false
      //   this.modelflag = false
      // }, 100)
    }

  }

  setEroorMessage(message) {
    this.errorMessage = message;
    console.log("this.errorMessage", this.errorMessage)
  }

  actualWindow() {
    this.functionflagValue = 'windowActualSize';
    this.WinSizeCall = false;
    this.flagForShowThreeJsInDripsTemplet = true;
    if (this.isCheckedFocus) {
      this.modelflag = true
    } else {
      this.storyFlag = true
    }
  }


  async openNews3DPassVRMValue(
    envUrl,
    AvatarUrl,
    snap,
    upperwear,
    bottomWear,
    footWear,
    headwear
    , event) {
    alert('new 3')
    event.stopPropagation();
    this.showloadhingFlage = true
    let dataUrl = await this.convertImageToBase64(snap.SnapshotFileUrl);

    if (dataUrl != undefined) {
      this.showloadhingFlage = false
    }
    this.WinSizeCall = true
    this.errorMessage = '';
    this.dataBlobUrl = "";
    let Garments = this.garmentsCollection.find(
      (gaementList) =>
        gaementList.id ===
        (snap.HeadwearID ||
          snap.UpperwearID ||
          snap.BottomwearID ||
          snap.FootwearID)
    );
    this.envUrl = envUrl;
    this.avatraUrls = AvatarUrl;
    this.garmenturl = upperwear;
    this.garmentbottom = bottomWear;
    this.vrmAvtarUrl = snap.vrmURL;
    this.genders = Garments.Gender;
    this.headgear = headwear;
    this.footwear = footWear;
    this.storyID = snap.StoryID;
    this.dataBlobUrl = dataUrl;
    this.snapshotUrl = snap;
    this.snapshot = snap
    this.passId = snap.id;
    this.flagForShowThreeJsInDripsTemplet = true;
    this.functionflagValue = 'windowActualSize';
    // document.getElementById('loadingScreen');
    this.functionflagValue =
      'LoadVRMAvatar' + '_' + this.vrmAvtarUrl + '_StoryID' + this.storyID;
    this.emitAvatarNewsData()

  }






  onDocumentClick(event: MouseEvent) {
    if (this.closeDiv && !this.isClickInsidePanel(event)) {
      this.closePanel();
    }
  }

  isClickInsidePanel(event: MouseEvent): boolean {
    const panel = document.querySelector('.sidenav');
    if (panel) {
      return panel.contains(event.target as Node);
    }
    return false;
  }

  closePanel() {
    this.wholeDiv = true;
    this.closeDiv = false;
    this.userCollapseFlag = false;
    this.avatarCollapseFlag = false;
    this.evenCollapseFlag = false;
    this.germentCollapseFlag = false;
    this.brandCollapseFlag = false;
  }

  ngOnDestroy() {
    this.initialMemoryUsage = null;
    this.finalMemoryUsage = null;

    // this.subscriptions.unsubscribe(); 

    // Unsubscribe from all subscriptions
    // if ( this.scrollableDiv.nativeElement) {
    //   this.scrollableDiv.nativeElement.removeEventListener('scroll', this.onVerticalScroll.bind(this));
    // }
    // Remove scroll listeners from horizontalScrollContainers
    // this.horizontalScrollContainers.forEach((container,index) => {
    //   container.nativeElement.removeEventListener('scroll', (event) => this.onHorizontalScroll(event, index));
    // });

  }

  callzipmemoryclear() {
    this.initialMemoryUsage = null;
    this.finalMemoryUsage = null;
  }

  clickOnPanel(event) {
    event.stopPropagation()
  }


  scrollToCard(snpId) {
    console.log(snpId)
    const element = document.getElementById('element_card' + snpId);
    console.log(element)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.activeIndex = snpId;
    }
  }

  async brandPanelDataFunction(brandId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/brandPanelDetailswithLogoUrl";
    return this.http.post<any>(functionUrl, { brandId: brandId }).toPromise();
  }
  async garmentPanelDataFunction(garmentId, typeWear) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";
    return this.http.post<any>(functionUrl, { garmentId: garmentId, fileType: typeWear }).toPromise();
  }

  async userPanelDataFunction(userId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getUserPanelDetails";
    return this.http.post<any>(functionUrl, { userId: userId }).toPromise();
  }

  async avatarPanelDataFunction(userId, avatarId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getAvatarPanelDetails";
    return this.http.post<any>(functionUrl, { userId: userId, avatarId: avatarId }).toPromise();
  }

  async environmentPanelDataFunction(storageUrl) {
    let functionUrl = " https://us-central1-yolomoves-fb435.cloudfunctions.net/observeEnvironmentsByStorageURL";
    return this.http.post<any>(functionUrl, { storageURL: storageUrl }).toPromise();
  }


  emitAvatarNewsData() {
    this.avatrVrmValueEmitter.emit({
      envUrl: this.envUrl,
      avatraUrls: this.avatraUrls,
      garmenturl: this.garmenturl,
      garmentbottom: this.garmentbottom,
      genders: this.genders,
      headgear: this.headgear,
      footwear: this.footwear,
      storyID: this.storyID,
      dataBlobUrl: this.dataBlobUrl,
      snapshotUrl: this.snapshotUrl,
      passId: this.passId,
      flagForShowThreeJs: this.flagForShowThreeJs,
      flagForShowThreeJsInDripsTemplet: this.flagForShowThreeJsInDripsTemplet,
      functionflagValue: this.functionflagValue
    });
  }

  emitVRmAvatarNewsData() {
    this.avatrNewVrmValueEmitter.emit({
      envUrl: this.envUrl,
      avatraUrls: this.avatraUrls,
      garmenturl: this.garmenturl,
      garmentbottom: this.garmentbottom,
      genders: this.genders,
      headgear: this.headgear,
      footwear: this.footwear,
      storyID: this.storyID,
      dataBlobUrl: this.dataBlobUrl,
      snapshotUrl: this.snapshotUrl,
      passId: this.passId,
      vrmURL: this.vrmAvtarUrl,
      flagForShowThreeJs: this.flagForShowThreeJs,
      flagForShowThreeJsInDripsTemplet: this.flagForShowThreeJsInDripsTemplet,
      functionflagValue: this.functionflagValue
    });
  }


  async fileUrlUpadate(data) {
    console.log(data)
    let garment = data
    let zipNmae = 'pack0';
    let packlocation =
      'AvatarBlenderClothes%2F' +
      garment.BrandID +
      '_' +
      garment.Gender +
      '%2F' +
      zipNmae;
    let garmentLink =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      packlocation +
      '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    const url = garmentLink;
    const cloth = garment.id;
    console.log("url..............", url)

    await this.commonService.downloadAndProcessPack(url, cloth).subscribe({
      next: (response) => console.log('Success:', response),
      error: (error) => console.error('Error:', error)
    });

  }



  emitGarmentDataToParent() {
    let filePathNewTemp = this.garmentData.userFiles.find((file) => file.link == this.selecteGarmentId && file.fileType == 'Garment360Cache');
    let findObject = this.garmentData.brand
    let file2Dimages = this.garmentData.userFiles.filter((file) => file.link == this.selecteGarmentId && file.fileType == 'GarmentUpload2D');
    let file3Dimages = this.garmentData.userFiles.filter((file) => file.link == this.selecteGarmentId && file.fileType == 'GarmentUpload3D');
    this.clothDetails = this.garmentData.garment
    const data = {
      brandObject: findObject,
      closeDiv: this.closeDiv,
      wholeDiv: this.wholeDiv,
      avatarCollapseFlag: this.avatarCollapseFlag,
      brandCollapseFlag: this.brandCollapseFlag,
      evenCollapseFlag: this.evenCollapseFlag,
      garmentPanelFlag: this.garmentPanelFlag,
      typeOfGarment: this.typeOfGarment,
      source: filePathNewTemp.fileurl,
      brandStoryScrollerHeight: this.brandStoryScrollerHeight,
      clotObject: this.clothDetails,
      userFile: file2Dimages,
      userFile3D: file3Dimages,
      garmentStory: this.garmentData.snapshots.object
    };

    this.garmentDataEmitter.emit(data);
    this.loadPanelLoader = false

  }

  emitDataToParent() {
    const data = {
      // snpObject: this.snpObject,
      closeDiv: this.closeDiv,
      wholeDiv: this.wholeDiv,
      avatarCollapseFlag: this.avatarCollapseFlag,
      brandCollapseFlag: this.brandCollapseFlag,
      evenCollapseFlag: this.evenCollapseFlag,
      garmentPanelFlag: this.garmentPanelFlag,
      userData: this.AvatarData,
      brandStoryScrollerHeight: this.brandStoryScrollerHeight
    };
    this.dataEmitter.emit(data);
  }

  emitUserDataToParent() {
    const data = {
      snpObject: this.userData,
      closeDiv: this.closeDiv,
      wholeDiv: this.wholeDiv,
      avatarCollapseFlag: this.avatarCollapseFlag,
      brandCollapseFlag: this.brandCollapseFlag,
      evenCollapseFlag: this.evenCollapseFlag,
      garmentPanelFlag: this.garmentPanelFlag,
      userData: this.userData,
      brandStoryScrollerHeight: this.brandStoryScrollerHeight,
      userWholeObject: this.UserData
    };
    this.userDataEmitter.emit(data);
  }


  emitEnvironmentDataToParent() {
    const data = {
      gltfImgSrc: this.gltfImgSrc,
      skyBoXall: this.skyBoXall,
      twoDprivew: this.twoDprivew,
      evsname: this.evsname,
      evsdiplaynameALL: this.evsdiplaynameALL,
      environmentFileName: this.environmentFileName,
      hideFlag: this.hideFlag,
      brandStoryScrollerHeight: this.brandStoryScrollerHeight,
      evenWholeData: this.environmentData
    };
    this.environmentDataEmitter.emit(data);
  }

  showTempPanel() {
    let flag;
    switch (true) {
      case this.mainMenuFlag:
        flag = false;
        break;
      case this.showpanel:
        flag = true;
        break;
      default:
        flag = true; // Or set to any other default value if needed
        break;
    }
    return flag;
  }

  checkCurrentRoute(): void {
    this.activatedRoute.url.subscribe(url => {
      this.pageFlagRout = url[0].path
      // Check if the URL matches your condition
    });



    if (this.pageFlagRout == 'drip') {
      let title = 'Yologram - drip';
      this.commonService.changeTitle(title);
    }
  }



  handleAnimationList(){
    this.animationListShow = !this.animationListShow
  }


  updateWindowDimensions() {
    this.windowHeight = window.innerHeight;
     this.windowWidth = window.innerWidth;
     this.isMobile = window.innerWidth <= 768; // You can adjust the width threshold for mobile mode

    console.log('Window Height:',   this.windowHeight);
    console.log('Window Width:',  this.windowWidth);

    // You can also use these dimensions to set styles or perform other logic
  }

  deleteSnapshotFile(id: any) {
    var confirmation = confirm('Are you sure to delete this Post ?');
    if (!confirmation) return;
    this.isLoading = true;
    this.firestore.collection("SnapshotDetails").doc(id).delete().then(() => {
      let filepath = "Snapshots/" + id
      this.storage.ref(filepath).delete();
      this.isLoading = false;
    }).catch((error) => {
    });;
  }

  openSearchPanel(snap: any,event) {
    this.emmitSearch.emit({
      snapshot: snap,
      event: event
    });
  }

}







