<div  #sliderRefAll  class="slider-container" >
    <div class="slider-item" *ngFor="let image of images" >
        <img (click)="seletctOption(image)"  [src]="image.img" alt="Image" class="image"
            [ngClass]="{'selected-image': selectedImage === image}">
    </div>
</div>
<div class="search-container">
    <input type="text" placeholder="Search" [(ngModel)]="searchTerm" class="search-input">
    <i class="bi bi-x clear-icon" *ngIf="searchTerm" (click)="clearSearch()"></i>
    <i class="bi bi-search search-icon" (click)="clickSearch()"></i>
</div>


<div class="user-container" *ngIf="this.selectedImage?.value === 'User' && showSearchContainer">
    <div class="slider-container-main">
        <div class="d-flex p-1 m-1" *ngFor="let image of filterUser" (click)="seletctUserFromList(image)">
            <img  [src]="image?.ProfilePicture" alt="Image" class="image rounded-circle m-1">
            <h6 class="m-1">{{image?.DisplayName}}</h6>
        </div>
    </div>
    <div *ngIf="this.selectedUserFromList"  class=" col-lg-12 band" style="margin-top: 50px;">
        <div class="static-band" style="margin-left: -5px  !important;">
            <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                <img class="img-thumbnail rounded-circle" style="
                          width: 40px !important;
                          height: 40px !important;
                          margin-top: -36px;" [src]="selectedUserFromList?.ProfilePicture">

                <div class="w-100 col-12 d-flex ">
                    <div class="col-9 text-start">
                        <h5 class="m-1 text-start text-black profile-name">{{selectedUserFromList?.DisplayName}}</h5>
                    </div>
                    <div class="col-3 "><button type="button" style="font-size: 10px;"
                            class="btn btn-outline-info follow-btn">Follow</button>
                    </div>
                </div>
            </div>
            <div>
                <app-image-slide [images]="imagesDummy"></app-image-slide>
            </div>
        </div>
    </div>
    <div *ngIf="this.selectedUserFromList" class="text-center">
        <button class="transparent-button search-button">Select</button>
    </div>
</div>
<div class="avatar-container" *ngIf="this.selectedImage?.value === 'brand' && showSearchContainer">
    Brand search
</div>
<div class="brand-container"  *ngIf="this.selectedImage?.value === 'Garment' && showSearchContainer">
    Garment search
</div>
<div class="garment-container"  *ngIf="this.selectedImage?.value === 'space' && showSearchContainer">
    Spaces search

</div>
<div class="space-container"  *ngIf="this.selectedImage?.value === 'avatar'&& showSearchContainer">
    Avatar search
</div>