import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { NavigationCard } from 'src/app/services/navigation-card-services';
declare var $: any; 
interface ModelViewerElement extends HTMLElement {
  toBlob: (options?: { idealAspect?: boolean }) => Promise<Blob>;
}
@Component({
  selector: 'app-caraosal-popup',
  templateUrl: './caraosal-popup.component.html',
  styleUrls: ['./caraosal-popup.component.css']
})
export class CaraosalPopupComponent {
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;

  @Input() scrollingImages: any = [];
  @Input() delete: boolean;
  @Input() deleteGarmentUpload: boolean;
  @Input() UserId: any;
  @Input() UserProfile: any;
  @Input() imageID: any;
  @Input() imageDocId: any;
  @Input() storeFrontFlag: any;
  @Input() modelViewerFlag:boolean;
  @Input() storeSelectedImage: any;

  @Input() storeSelectedGLTFFile: any;
  @Input() storeSelectedVideo: any;
  @Input() storeSelectedFlag: boolean | false;
  
  
  
  
  

  @Input() storeManquin: any;
  @Input() showStoreFrontSaveImage: any;
  @Input() selectedGarmentCacheUrlRight: any;
  @Input() selectedGarmentCacheUrlLeft: any;
  @Input() showStoreFrontSaveImageRight: any;
  @Input() storeFrontData: any;

  

  
  @Input() clothId: any;

  @Input() fileClothName: any;
  @Input() id: any;
  @Input()   GarmentSelectedFlag: boolean | false;


  




  

  
  

  

  

  userUploadedFileList: any = [];
  filelist: any = [];
  userFiledetails: any = [];
  allGarmentUploadImages: any = [];
  fileName: any;
  nameFlag: boolean = true;
  top:any='-300px'
  left:any='50%'


    constructor(private modal: NgbModal,
      private renderer:Renderer2,
      private navigate:NavigationCard,
      private firestore: AngularFirestore,
      private storage: AngularFireStorage,
      
      ){
  
        this.userFilesList.subscribe((userFile) => {
          this.userUploadedFileList = userFile
          this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
          let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.UserId)
          this.userFiledetails = filelist
      });
 


    }

    ngOnChanges(changes) {
      console.log(changes)
      console.log('modelViewerFlag',this.modelViewerFlag)
      this.allGarmentUploadImages = this.scrollingImages
      console.log(this.scrollingImages)
      new window['bootstrap'].Carousel(document.getElementById('imageCarousel'), {
        interval: true 
      });    

      this. detectTopLeftCorners()


    }

    ngAfterViewInit(): void {
      this. detectTopLeftCorners()

    }
   
  
    detectTopLeftCorners() {
      const carouselItems = document.querySelectorAll('.carousel-item');
      carouselItems.forEach(item => {
        const image = item.querySelector('img');
        if (image) {
          const rect = image.getBoundingClientRect();
          // const topLeftX = rect.left;
          // const topLeftY = rect.top;
          // console.log('Image:', image.alt);
          // console.log('Top-left corner X-coordinate:', topLeftX + 'px');
          // console.log('Top-left corner Y-coordinate:', topLeftY + 'px');

          const topLeftX = (rect.left / rect.width) * 100; // Convert to percentage
          const topLeftY = (rect.top / rect.height) * 100; // Convert to percentage
    
          console.log('Image:', image.alt);
          console.log('Top-left corner X-coordinate:', topLeftX + '%');
          console.log('Top-left corner Y-coordinate:', topLeftY + '%');
          console.log('Top-left corner X-coordinate:', rect.height + '%');
          console.log('Top-left corner Y-coordinate:',  rect.width + '%');
          const left = rect.width +'px'
     

          const value = rect.width;
          const percentage = (value / 5) * 100;
          console.log(`${value} is ${percentage}% of 100%.`);
        }
      });
    }
    




    
    ngOnInit(): void {       
      this.allGarmentUploadImages = this.scrollingImages
    }


  

  
  
  
  
  
  

    editFileName($event){
      this.fileName = $event.target.value
    }

    updateFileName(data){
      this.nameFlag = true
      let object  = {
        'filename':  this.fileName 
      }
      this.firestore.collection('userfiles').doc(data["id"]).update(object);
      this.closePopUp()
    }

    selectName(){
      this.nameFlag = false
    }
  
    // deleteSnapshotFile(data: any) {
    //   this.allGarmentUploadImages = []
    //   console.log(data)
    //   if(this.deleteGarmentUpload){
    //     var confirmation = confirm('Are you sure to delete this File ?');
    //     if (!confirmation) return;
    //     this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
          
    //       // let filepath = "Uploads/" + data.link +"/"+ data.filename

    //       let filepath = data.filepath
    //       let miniUrlPath
    //       let imageFormats = ['jpg', 'jpeg', 'webp', 'png', 'gif'];
    //        imageFormats.map(format => {
    //         const baseName = filepath.replace(/\.[^/.]+$/, '');
    //       if(data['2DType']=='Video'){
    //           miniUrlPath =   `${baseName}_thumbnail.${format}`;
    //       } else{
    //          miniUrlPath = `${baseName}_128x128.${format}`;
    //       }
    //         return this.storage.ref(miniUrlPath).delete().toPromise()
    //           .catch(error => {
    //             if (error.code !== 'storage/object-not-found') {
    //               throw error;
    //             }
    //           });
    //       });
    //       this.storage.ref(filepath).delete();
    //     }).catch((error) => {
    //     });;
    //   }else{
    //     var confirmation = confirm('Are you sure to delete this File ?');
    //     if (!confirmation) return;
    //     this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
    //       let filepath = data.filepath
    //       // let filepath = "Uploads/" + data.userId +"/"+ data.filename
    //       this.storage.ref(filepath).delete();
    //     }).catch((error) => {
    //     });;
    //   }
    //     // this.images = []

    //     this.closePopUp()
    // }
    
    deleteSnapshotFile(data: any) {
      this.allGarmentUploadImages = [];
      console.log('data----.',data);
    
      const confirmation = confirm('Are you sure to delete this File?');
      if (!confirmation) return;
    
      this.firestore.collection("userfiles").doc(data.docId).delete().then(() => {
        const filepath = data.filepath;
        let imageFormats = ['jpg', 'jpeg', 'webp', 'png', 'gif'];
    
        // Delete thumbnail or resized images
        const deletePromises = imageFormats.map(format => {
          const baseName = filepath.replace(/\.[^/.]+$/, '');  // Remove file extension
          let miniUrlPath;
    
          if (data['2DType'] === 'Video') {
            miniUrlPath = `${baseName}_thumbnail.${format}`;
          } else {
            miniUrlPath = `${baseName}_128x128.${format}`;
          }
    
          return this.storage.ref(miniUrlPath).delete().toPromise()
            .catch(error => {
              if (error.code === 'storage/object-not-found') {
                console.log(`File not found: ${miniUrlPath}, skipping deletion`);
                return;  // Skip this error
              } else {
                throw error;  // Re-throw other errors
              }
            });
        });
    
        // Delete the original file
        const originalFileDeletion = this.storage.ref(filepath).delete().toPromise()
          .catch(error => {
            if (error.code === 'storage/object-not-found') {
              console.log(`Original file not found: ${filepath}, skipping deletion`);
              return;
            } else {
              throw error;
            }
          });
    
        // Wait for all deletions to complete
        return Promise.all([...deletePromises, originalFileDeletion]);
      }).then(() => {
        console.log('All files deleted successfully');
        this.closePopUp();
      }).catch(error => {
        console.error('Error deleting files:', error);
      });
    }
    
    
    closePopUp() {
      this.modal.dismissAll(CaraosalPopupComponent)
    }

    async save(data) {
      alert('save')
      const result = {
        success: true,
        data: data.docId // You can customize the result as needed
      };
      this.modal.dismissAll(result)
    }

    async saveCache(data) {
      let miniUrl = await this.generateAndUploadImage(data,this.fileClothName)
      await  this.saveAndUpdateCache(this.id,miniUrl)
      console.log(miniUrl)
      const result = {
        success: true,
        data: data.docId // You can customize the result as needed
      };
      this.modal.dismissAll(result)
    }

    async saveCacheFRomStore(data) {
      alert('save cache')
      const baseFilename = data.filepath.substring(data.filepath.lastIndexOf('/') + 1, data.filepath.lastIndexOf('.'));
      console.log('data',data)
      let miniUrl = await this.generateAndUploadImage(data,baseFilename)
      await  this.saveAndUpdateCache(data.docId,miniUrl)
      this.modal.dismissAll()
    }


    async generateAndUploadImage(ref: any, fileNameWithoutExtension: string) {
      await new Promise(resolve => setTimeout(resolve, 5000));
      const modelViewer = document.getElementById("viewer") as ModelViewerElement;
      if (modelViewer) {
          const blob = await modelViewer.toBlob({ idealAspect: false });
          const url = URL.createObjectURL(blob);
    
          // Add a 2-second delay
          await new Promise(resolve => setTimeout(resolve, 2000));
          
          // Create a reference for the mini image
          const miniImagePath = `Uploads/${this.clothId}/${fileNameWithoutExtension}_mini.png`;
          const miniImageRef = this.storage.ref(miniImagePath);
          
          // Upload the mini image
          await miniImageRef.put(blob);
          const miniImageUrl = await miniImageRef.getDownloadURL().toPromise();
          
          URL.revokeObjectURL(url);
          
          return miniImageUrl;
      } else {
          console.error('ModelViewer element not found');
          return '';
      }
    }
 
    async saveAndUpdateCache(docId, miniUrl) {
      const docRef = this.firestore.collection('userfiles').doc(docId);
      const updateObject = {
        fileurlMini: miniUrl, // Add the URL of the uploaded image
      };
      // Update the document with the new field
      try {
        await docRef.update(updateObject);
        console.log('Document successfully updated');
      alert('Document successfully updated')
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    }

}



