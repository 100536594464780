import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { Brand } from 'src/app/models/brand';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { DatePipe } from '@angular/common';
import { generateUUID } from 'three/src/math/MathUtils';
import { Observable, tap, finalize, timestamp } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import * as _ from 'lodash'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TwoDpopupComponent } from 'src/app/common/templets/twoDimagePopUp/twoDpopup';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CaraosalPopupComponent } from 'src/app/common/templets/caraosal-popup/caraosal-popup.component';
import { DomSanitizer } from '@angular/platform-browser';

interface ModelViewerElement extends HTMLElement {
  toBlob: (options?: { idealAspect?: boolean }) => Promise<Blob>;
}

@Component({
  selector: 'app-garment-edit',
  templateUrl: './garment-edit.component.html',
  styleUrls: ['./garment-edit.component.css']
})
export class GarmentEditComponent implements OnInit {
garmentScrollFlag: any;
showBrandFlag: any;
exclosiveMode: any;
filterBrandListNew: any = [];
brandNameSelected: any;
selectImage(_t496: number,arg1: any) {
throw new Error('Method not implemented.');
}
  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  snapshot!: Observable<any>;
  fileUrl!: string;
  form: FormGroup;
  user: any = null;
  userid: string = "";
  id: string = "";
  picture!: string;
  selected: any;

  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollection = this.firestore.collection('garment-collection').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollectionItems = this.firestore.collection('garment-collection-items').valueChanges({ idField: 'id' }) as Observable<any[]>;
  
  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  @ViewChild('brandStoriyScrollA', { static: false }) brandStoriyScrollA: ElementRef;
  private scrollEventListener: () => void;
  private slider: HTMLElement;




  userId: string;
  userSnapShotList: any;
  profileName: any
  userName: any = []
  userDetails: any
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  // id: any;
  isLoadingforUserDetails: boolean = true;
  profileNameSrc: any = "profile";
  brandCollection: any = {}
  collectionObject: any = {}
  brandId: any
  brandProfileDetails: any = []
  garmentsCollection: any = []
  snapShotBrandList = [];
  brandGarments: any = []
  brandBoard: any
  womensBrands: any;
  mensBrands: any;
  clothDetailsList: any = {};
  editGarmentDetails: any;
  userUploadedFileList: any = [];
  userFiledetails: any = [];
  userFiledetails3d: any = [];
  collectionData: any = [];
  collectionDetails: any
  counter: number = 2;
  showImages: any = {}
  gltfImgSrc = ''
  edit: any
  brandIdStyle: any
  editfile = 'edit'
  clothId: any;
  file: any
  envName: any = ''
  hideName: any
  uploadFileName: string = ''
  FieldValue: any
  popup: any
  collectionName: any
  collectionFilterData: any = []
  garmentItemId: any;
  nameOfCollection: any;
  setVaalue: any = '';
  selectedOption: any;
  filterdataGarmentCollection: any;
  idSelected: any;
  linkId: any;
  selectedTab:any
  deleteflag: boolean = true;

  isMouseDown: boolean = false;
  startX: number = 0;
  scrollLeft: number = 0;
  scrollFlag: boolean
  selectedBrand: any;
  brandList: any = []


  //panel for the sidenav






  images = [
    { "image": "./assets/garment-upload-dummy/img-1.jpg" },
    { "image": "./assets/garment-upload-dummy/img-2.jpg" },
    { "image": "./assets/garment-upload-dummy/img-3.jpg" },
    { "image": "./assets/garment-upload-dummy/img-4.jpg" },
    { "image": "./assets/garment-upload-dummy/img-5.jpg" },
    { "image": "./assets/garment-upload-dummy/img-6.jpg" },
    { "image": "./assets/garment-upload-dummy/img-7.jpg" },
    { "image": "./assets/garment-upload-dummy/img-8.jpg" },
    { "image": "./assets/garment-upload-dummy/img-9.jpg" },
  ]

  fileObject: any = {};
  logInFlag: boolean;
  videoUrl: string;
  byScroll: boolean = true
  loading: any;
  selectedBrandId: any;
  tab: any;
  loadingState: any;
  flipFlag: any;
  isLoadingStyle: any;
  isloading: any;
  errMessage: string;
  isLoading: boolean;
  selectedBrandID: any;
  selectedGender: string;
  isloadingBrandPanel: boolean = true;
  showTheBrandMode: boolean = true
  packJsonObject = [];
  zipList: any = {}
  testVar: any = '';
  pack0 = "%2Fpack0";
  pack1 = "%2Fpack1";
  callDownloadcheck: any;
  // private activeDownloads: Set<string> = new Set();
  callDownloadcache: any;
  downloadFlag: boolean = true
  newZipLists: any = {};
  swichImageFlage: boolean = true;
  garmentClothType: any;
  source: any;
  style: string = 'treads';
  packUrl: string = '';

  
  @ViewChild('scrollContainerWUpper') scrollContainerWUpper: ElementRef;
  @ViewChild('scrollContainerWBottom') scrollContainerWBottom: ElementRef;
  @ViewChild('scrollContainerWHeadGear') scrollContainerWHeadGear: ElementRef;
  @ViewChild('scrollContainerWFootwear') scrollContainerWFootwear: ElementRef;


  @ViewChild('scrollContainerMUpper') scrollContainerMUpper: ElementRef;
  @ViewChild('scrollContainerMBottom') scrollContainerMBottom: ElementRef;
  @ViewChild('scrollContainerMHeadGear') scrollContainerMHeadGear: ElementRef;
  @ViewChild('scrollContainerMFootwear') scrollContainerMFootwear: ElementRef;
  
  brandFlag: any;
  garmentImgage: any = ''
  garmentClothId: any = ''
  typeWear: any;
  clothDetails: any;
  selectedIcon: string;
  iconselctFlag: boolean;

  showMore: boolean = false;
  isloadingPanel: boolean = false;

  garmentData: any = []
  userFileList: any = []

  userSnapShotListGarment: any = []
  brandObject: any
  dummyText: any = ''
  filteredData: any;
  selectedImageIndex: any;
  sideNavHeight:number;
  windowHeight: number;



  constructor(
    private firestore: AngularFirestore,
    public sanitizer: DomSanitizer,
    private storage: AngularFireStorage,
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private db: AngularFirestore,
    private commonService: CommonService,
    private modal: NgbModal,
    private http: HttpClient,


  ) {
    this.commonService.getUser().subscribe((user: any) => {
      this.userid = user?.uid;
      this.id
    })
   
    this.brandsFilesList.subscribe(brandList => {
      this.brandList = brandList
      this.brandList.forEach(async (brand) => {
        let type = 'b';
        let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
        brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
      console.log("brand List",this.brandList)
    }

    )

    

  }



  ngOnInit(): void {
    
    this.windowHeight = window.innerHeight;
    let title = 'Yologram - edit-garment'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.dataSharingService.userBrandGarmentObservable.subscribe(garments => {
      this.brandGarments = garments
    })

    this.dataSharingService.userSnapShotObservable.subscribe(snapshot => {
      this.userSnapShotList = snapshot
    })

    this.dataSharingService.userclothDetailsListObservable.subscribe(cloth => {
      this.clothDetailsList = cloth
      console.log("clothDetailsList",this.clothDetailsList)
      this.clothId = this.clothDetailsList?.id
    })

    this.dataSharingService.userBrandBoardObservable.subscribe(board => {
      this.brandBoard = board
    })


    this.userFilesList.subscribe((userFile) => {
      // this.userUploadedFileList = userFile
      // let clothId = this.clothDetailsList.id
      // let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId)
      // this.userFiledetails = filelist.sort((a, b) => (a.date) - (b.date));
      // this.userFiledetails = _.orderBy(filelist, [item => item.date], ['desc']);


      this.userUploadedFileList = userFile
      let clothId = this.clothDetailsList
      let filelist2d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId?.id && uploadlist.fileType == 'GarmentUpload2D')
      let filelist3d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId?.id && uploadlist.fileType == 'GarmentUpload3D')
      this.userFiledetails = filelist2d
      this.userFiledetails3d = filelist3d
    })


    this.route.paramMap.subscribe(paramMap => {
      this.popup = paramMap.get('Popup')
      this.brandId = paramMap.get('brandId');
      this.edit = paramMap.get('edit');
      this.brandIdStyle = paramMap.get('brandIdStyle')
      this.brandsFilesList.subscribe(brandList => {
        this.brandCollection = brandList

        let brandIDforProfile = this.brandCollection.find((brandListProfile) => brandListProfile.id == this.brandId)
        console.log('brandIDforProfile',brandIDforProfile)
        this.brandProfileDetails = brandIDforProfile
        this.mensBrands = brandList.filter((b: any) => b.Gender === 'M' || b.Gender === 'B');
        this.womensBrands = brandList.filter((b: any) => b.Gender === 'F' || b.Gender === 'B');
      })
    })

    this.userGarmentCollection.subscribe((collectiondata) => {
      this.collectionData = collectiondata
    })

    this.userGarmentCollectionItems.subscribe((collectiondata) => {
      this.collectionDetails = collectiondata
      this.filterCollection()
    })

    // this.setupInfiniteScroll();

  }
  files: File[] = [];

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + newPath + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }


  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  call() {
    if (this.popup != undefined) {
      this.selected = 'data-bs-toggle="modal" data-bs-target="#upload2D"'
    }
  }


  filterCollection() {
    let brandId = this.clothDetailsList?.BrandID
    let Gender = this.clothDetailsList?.Gender
    let filteredCollection = this.collectionData.filter((collection) =>
      collection.BrandId === brandId && (collection.Gender === Gender || collection.Gender == 'B'))
    this.collectionFilterData = filteredCollection.filter((data) => data.status == "A")
  }

  saveCollection(colection) {
    let seletctCollection = this.collectionDetails.find((data) => data.GarmentCollectionID == colection.id)
    if (seletctCollection == undefined) {
      let garmentId = this.clothDetailsList.id
      let collectionObject = {
        GarmentCollectionID: colection.id,
        GarmentID: garmentId
      }
      this.firestore.collection('garment-collection-items').add(collectionObject);
    }

    // let garmentId = this.clothDetailsList.id
    // let collectionObject = {
    //   GarmentCollectionID: colection.id,
    //   GarmentID: garmentId
    // }
    // console.log("collectionObject", collectionObject)
    // this.firestore.collection('garment-collection-items').add(collectionObject);

  }

  filterCollectionName(id) {

    // let garmentCollectionId = this.collectionDetails.find((colection) => colection.id === id)
    // let getCollection = this.collectionData.find((colection) => colection.id === garmentCollectionId.GarmentCollectionID)
    // let collectionName = getCollection != undefined && getCollection.CollectionName != undefined ? getCollection.CollectionName : 'None';
    // return collectionName

    let garmentCollectionId = (this.collectionDetails != undefined && this.collectionDetails).find((colection) => colection.id === id)
    let getCollection = this.collectionData.find((colection) => colection.id === garmentCollectionId.GarmentCollectionID && colection.status == 'A')
    let name = getCollection != undefined && getCollection.CollectionName != undefined ? getCollection.CollectionName : '';
    return name
  }

  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId && snapshot.StoryID == storyid);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
  }

  getUserProfile() {
    let userId = this.userId
    this.dataSharingService.sendUserSnapShots(userId);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  getBrandStories(brand: any) {
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': this.brandProfileDetails['id'] }])
  }

  getFeed(brand: any, brandName: any) {
    let boardName = this.brandBoard
    if (this.brandIdStyle != undefined) { this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': this.brandIdStyle, 'storyId': brand.StoryID, 'board': boardName }]) }
    else this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brandName.id, 'storyId': brand.StoryID, 'board': boardName }])
  }

  getBrandStyle(cloths: any) {
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    let boardName = this.brandBoard
    this.router.navigate(['yologram-social/yologram-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'edit': this.editfile }])
  }

  getStoryForGenderCloth(brandIds) {
    this.selectedTab = "story"
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    let brandid = brandIds.id
    if (this.edit != undefined) {
      this.router.navigate(['yologram-social/yologram-garment-edit', { 'brandId': brandid, }])
    } else {
      this.router.navigate(['yologram-social/yologram-garment-profile', { 'brandId': brandid, }])
    }
  }


  setGarmentCollection($event) {
    this.editGarmentDetails.CollectionName = $event.target.value

    if ($event.target.value == '') {
      this.editGarmentDetails.CollectionName = null
    }
  }
  setGarmentName($event) {
    this.editGarmentDetails.Name = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.CollectionName = null
      alert("Please Enter The Garment Name")
      return;
    }
  }
  setGarmentSKU($event) {
    this.editGarmentDetails.SKU = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.SKU = null
    }

  }
  setGarmentDetails($event) {
    this.editGarmentDetails.Details = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.Details = null
    }
  }
  setGarmentThumbCenterURL($event) {
    this.editGarmentDetails.ThumbCenterURL = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.ThumbCenterURL = null
    }
  }
  setGarmentThumbLeftURL($event) {
    this.editGarmentDetails.ThumbLeftURL = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.ThumbLeftURL = null
    }
  }
  setGarmentThumbRightURL($event) {
    this.editGarmentDetails.ThumbRightURL = $event.target.value
    if ($event.target.value == '') {
      this.editGarmentDetails.ThumbRightURL = null
    }
  }
  garmentDetailsEdit(editPage) {
    this.clothDetailsList = editPage
    this.editGarmentDetails = editPage;
    let findLinkId = this.collectionDetails.find((data) => data.id == this.editGarmentDetails.CollectionName)
    let filterdata = this.collectionData.find((data) => data.id == findLinkId.GarmentCollectionID)
    this.linkId = findLinkId?.id
    this.filterdataGarmentCollection = filterdata
    this.selectedOption = filterdata && filterdata.id != undefined ? filterdata.id : 'None';
  }

  updateGarmentDetailsEdit() {
    if (this.collectionName != '' && this.collectionName != undefined) {
      this.saveCollection(this.collectionObject)
      let seletctCollection = this.collectionDetails.find((data) => data.GarmentCollectionID == this.collectionObject.id)
      this.editGarmentDetails.CollectionName = seletctCollection.id
    }
    else {
      this.editGarmentDetails.CollectionName = null;
    }
    let object = {
      CollectionName: this.editGarmentDetails.CollectionName,
      SKU: this.editGarmentDetails.SKU,
      Details: this.editGarmentDetails.Details,
      ThumbCenterURL: this.editGarmentDetails.ThumbCenterURL,
      ThumbLeftURL: this.editGarmentDetails.ThumbLeftURL,
      ThumbRightURL: this.editGarmentDetails.ThumbRightURL,
      Name: this.editGarmentDetails.Name
    };
    this.firestore.collection('garments').doc(this.editGarmentDetails["id"]).update(object);

    this.firestore.collection("garment-collection-items").doc(this.linkId).delete().then(() => {
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }

  incrementImgCounter() {
    this.counter = this.counter + 2;
  }
  onDrop($event) {
    this.onDropFiles($event.target.files);
  }
  onDropFiles(filesUploaded: any) {
    for (let i = 0; i < filesUploaded.length; i++) {
      this.files.push(filesUploaded.item(i));
    }
  }
  validation() {

  }
  
  // start2DFileUpload($event: any) {
  //   let uploadFile = $event.target.files[0];
  //   console.log("uploadFile",uploadFile)
  //   this.uploadFileName = uploadFile.name;
  //   console.log("uploadFileName", this.uploadFileName)
  //   let ext = this.uploadFileName.split('.')[this.uploadFileName.length - 1];
  //   const lastIndex =  this.uploadFileName.lastIndexOf('.');
  //   const fileNameWithoutExtension = lastIndex !== -1 ?  this.uploadFileName.slice(0, lastIndex) :  this.uploadFileName;
  //   console.log(fileNameWithoutExtension);
  //   let uploadCustomerFilename = this.envName + "." + ext
  //   const pathEvn = `Uploads/${this.clothId}/${this.uploadFileName}`;
  //   const filename = `${this.file}`;
  //   const ref = this.storage.ref(pathEvn);
  //   this.task = this.storage.upload(pathEvn, uploadFile);
  //   this.percentage = this.task.percentageChanges();
  //   let new_date = new Date();
  //   const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
  //   const uid = generateUUID();
  //   let uploadObject = {
  //     id: uid,
  //     filename:this.envName != ''  ? this.envName  : fileNameWithoutExtension,
  //     fileurl: '',
  //     fileurlMini: '',
  //     filepath: pathEvn,
  //     date: date,
  //     fileType: 'GarmentUpload2D',
  //     userId: this.userid,
  //     link: this.clothId,
  //   }
  //   this.task.then(async snapshot => {
  //     let downloadURLTest = ref.getDownloadURL();
  //     await downloadURLTest.subscribe(async url => {
  //       uploadObject.fileurl = url

  //       // this.db.collection('userfiles').add(uploadObject);
  //       const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_128x128$&");
  //       const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
  //       let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
  //       try {
  //           const response = await this.http.post(functionUrl, { 
  //               imageUrl: url, 
  //               path: compressedFilePath
  //           }).toPromise();
  //           uploadObject.fileurlMini = response['fileurlMini'];
  //           console.log(" uploadObject.fileurlMini", uploadObject.fileurlMini)

  //           // Ensure fileurlMini is defined before adding to Firestore
  //           if (uploadObject.fileurlMini) {
  //               console.log("uploadObject", uploadObject);
  //               await this.db.collection('userfiles').add(uploadObject);
  //           } else {
  //               console.error('File URL Mini is undefined');
  //           }
  //       } catch (error) {
  //           console.error('Error compressing file:', error);
  //       }
  //     });
  //   })
  // }

  async start2DFileUpload(event: any) {
    this.clothId = this.clothDetails.id
    alert( this.clothId)
    const uploadFile = event.target.files[0];
    if (!uploadFile) return;
    
    this.uploadFileName = uploadFile.name;
    let ext = this.uploadFileName.split('.').pop().toLowerCase();
    const lastIndex = this.uploadFileName.lastIndexOf('.');
    const fileNameWithoutExtension = lastIndex !== -1 ? this.uploadFileName.slice(0, lastIndex) : this.uploadFileName;

    const pathEvn = `Uploads/${this.clothId}/${this.uploadFileName}`;
    const ref = this.storage.ref(pathEvn);
    

    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject = {
      id: uid,
      filename: this.envName !== '' ? this.envName : fileNameWithoutExtension,
      fileurl: '',
      fileurlMini: '',
      filepath: pathEvn,
      date: date,
      fileType: 'GarmentUpload2D',
      userId: this.userid,
      link: this.clothId,
    };

    try {
      // Check if file already exists
      const existingFiles = await this.db.collection('userfiles', ref => ref.where('filepath', '==', pathEvn)).get().toPromise();
      if (!existingFiles.empty) {
        alert('File already exists. Upload aborted.')
        console.log('File already exists. Upload aborted.');
        return;
      }
    } catch (error) {
      console.error('Error checking existing files:', error);
    }
    this.task = this.storage.upload(pathEvn, uploadFile);
    this.percentage = this.task.percentageChanges()

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv'];
    let fileType;

    if (imageExtensions.includes(ext)) {
      console.log('File is an image:', this.uploadFileName);
      fileType = 'image';
    } else if (videoExtensions.includes(ext)) {
      console.log('File is a video:', this.uploadFileName);
      fileType = 'video';
    } else {
      console.log('File type is unknown:', this.uploadFileName);
    }

    this.task.snapshotChanges().pipe(
      tap(console.log),
      finalize(async () => {
        const downloadURLTest = ref.getDownloadURL().toPromise();
        uploadObject.fileurl = await downloadURLTest;

        if (fileType === 'video') {
          try {
            const thumbnailUrl = await this.getThumbnailForVideo(uploadObject.fileurl);
            const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_thumbnail.png");
            const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
            const thumbRef = this.storage.ref(compressedFilePath);
            await thumbRef.putString(thumbnailUrl, 'data_url');
            uploadObject.fileurlMini = await thumbRef.getDownloadURL().toPromise();

            if (uploadObject.fileurlMini) {
              console.log("uploadObject", uploadObject);
              const docRef = await this.db.collection('userfiles').add(uploadObject);
              await docRef.update({ '2DType': 'Video' });
            } else {
              console.error('File URL Mini is undefined');
            }
          } catch (error) {
            console.error('Error generating or uploading thumbnail:', error);
          }
        } else {
          const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_128x128$&");
          const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
          let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
          try {
            const response = await this.http.post(functionUrl, {
              imageUrl: uploadObject.fileurl,
              path: compressedFilePath
            }).toPromise();
            uploadObject.fileurlMini = response['fileurlMini'];
            console.log("uploadObject.fileurlMini", uploadObject.fileurlMini);

            if (uploadObject.fileurlMini) {
              console.log("uploadObject", uploadObject);
              await this.db.collection('userfiles').add(uploadObject);
            } else {
              console.error('File URL Mini is undefined');
            }
          } catch (error) {
            console.error('Error compressing file:', error);
          }
        }
      })
    ).subscribe();
  }

  

  
  // async getThumbnailForVideo(videoUrl: string): Promise<string> {
  //   return new Promise<string>((resolve, reject) => {
  //     const video = document.createElement('video');
  //     const canvas = document.createElement('canvas');
  //     video.style.display = 'none';
  //     canvas.style.display = 'none';
  
  //     // Set crossOrigin attribute to handle cross-origin video sources
  //     video.crossOrigin = 'anonymous';
  
  //     document.body.appendChild(video);
  //     document.body.appendChild(canvas);
  
  //     video.addEventListener('loadedmetadata', () => {
  //       video.width = video.videoWidth;
  //       video.height = video.videoHeight;
  //       canvas.width = video.videoWidth;
  //       canvas.height = video.videoHeight;
  //       video.currentTime = video.duration * 0.25;
  //     });
  
  //     video.addEventListener('seeked', () => {
  //       canvas.getContext('2d')!.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  //       const imageUrl = canvas.toDataURL('image/png');
  //       document.body.removeChild(video);
  //       document.body.removeChild(canvas);
  //       resolve(imageUrl);
  //     });
  
  //     video.addEventListener('error', (e) => {
  //       document.body.removeChild(video);
  //       document.body.removeChild(canvas);
  //       reject(e);
  //     });
  
  //     video.src = videoUrl;
  //   });
  // }

  async  getThumbnailForVideo(videoUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      video.style.display = 'none';
      canvas.style.display = 'none';
  
      // Set crossOrigin attribute to handle cross-origin video sources
      video.crossOrigin = 'anonymous';
  
      document.body.appendChild(video);
      document.body.appendChild(canvas);
  
      video.addEventListener('loadedmetadata', () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        video.currentTime = video.duration * 0.25;
      });
  
      video.addEventListener('seeked', () => {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  
          // Draw play button watermark
          const playButtonSize = Math.min(video.videoWidth, video.videoHeight) / 4;
          const centerX = video.videoWidth / 2;
          const centerY = video.videoHeight / 2;
  
          // Draw circle
          ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
          ctx.beginPath();
          ctx.arc(centerX, centerY, playButtonSize / 2, 0, 2 * Math.PI);
          ctx.fill();
  
          // Draw triangle (play icon)
          ctx.fillStyle = 'white';
          ctx.beginPath();
          ctx.moveTo(centerX - playButtonSize / 6, centerY - playButtonSize / 4);
          ctx.lineTo(centerX - playButtonSize / 6, centerY + playButtonSize / 4);
          ctx.lineTo(centerX + playButtonSize / 3, centerY);
          ctx.closePath();
          ctx.fill();
  
          const imageUrl = canvas.toDataURL('image/png');
          document.body.removeChild(video);
          document.body.removeChild(canvas);
          resolve(imageUrl);
        } else {

          document.body.removeChild(video);
          document.body.removeChild(canvas);

          reject(new Error('Unable to get canvas context'));
        }
      });
  
      video.addEventListener('error', (e) => {
        document.body.removeChild(video);
        document.body.removeChild(canvas);
        reject(e);
      });
  
      video.src = videoUrl;
    });
  }
  start3DFileUpload($event: any) {
    // if (this.envName != '' || this.envName != undefined) { return }
    this.clothId = this.clothDetails.id
    let uploadFile = $event.target.files[0];
    this.uploadFileName = uploadFile.name;
    let ext = this.uploadFileName.split('.')[1];
    let uploadCustomerFilename = this.envName + "." + ext
    const pathEvn = `Uploads/${this.clothId}/${this.uploadFileName}`;

    const lastIndex =  this.uploadFileName.lastIndexOf('.');
    const fileNameWithoutExtension = lastIndex !== -1 ?  this.uploadFileName.slice(0, lastIndex) :  this.uploadFileName;

    const filename = `${this.file}`;
    const ref = this.storage.ref(pathEvn);
    this.task = this.storage.upload(pathEvn, uploadFile);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject = {
      id: uid,
      filename:this.envName != ''  ? this.envName  : fileNameWithoutExtension,
      fileurl: '',
      filepath: pathEvn,
      date: date,
      fileType: 'GarmentUpload3D',
      userId: this.userid,
      link: this.clothId,
    }
    this.task.then(async snapshot => {
      let downloadURLTest = ref.getDownloadURL();
      await downloadURLTest.subscribe(url => {
        uploadObject.fileurl = url
        // console.log("Object adding ",uploadObject);
        // this.db.collection('userfiles').add(uploadObject).then((docRef) => {
        //   console.log("File uploaded", docRef);
        // });
        this.db.collection('userfiles').add(uploadObject);
      });
    })
  }


  getgarmentfeed(snapshot: any, cloths: any) {
    let snap = this.userSnapShotList
    let key = snapshot.UpperwearID || snapshot.UpperwearID || snapshot.UpperwearID || snapshot.UpperwearID
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['/garment', { 'brandId': cloths.BrandID, 'tab': 'story','clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'storyId': snapshot.StoryID,'key': key,}])
    // this.router.navigate([   '/garment', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key, 'storyId': snapshot.StoryID, 'tab': 'story' }])

 
  }

  openImageBox(fileurl) {
    let url = fileurl
    let showImage = this.userFiledetails.find((file) => file.fileurl == url)
    this.showImages = showImage
  }


  openModel(gltfImgSrc: string,fileObject) {
    const dialogRef = this.dialog.open(PopupThreeDComponent);
    this.fileObject = fileObject
    this.gltfImgSrc = gltfImgSrc
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.componentInstance.fileObject = this.fileObject;
    dialogRef.componentInstance.fileDeletFlag = this.deleteflag;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  scrollToStory(snap) {
    // this.selectedStoryId = ''
    // this.scrollFlag = false
    this.selectedTab = "user-story"
    // this.selectedStoryId = snap.StoryID
    // this.iconselctFlag = false
    // this.toggaleFlag = false
    // this.selectedIcon = ''
  }

  setEnvName($event) {
    this.hideName = true
    this.envName = $event.target.value;
    console.log("FileName", this.envName)
  }

  clear() {
    this.percentage = new Observable<undefined>();
    this.envName = ""
    this.uploadFileName = ""
    if (this.percentage) {
      this.envName = ''
    }
  }


  selectCollection($event) {
    this.collectionObject = {}
    this.collectionName = $event.target.value;
    if (this.collectionName != '')
      this.collectionObject = this.collectionData.find((collection) => collection.id === this.collectionName)
  }


  open2dPoup(boxImage) {
    const modalRef = this.modal.open(TwoDpopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.boxImage = boxImage;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  
  openStoreGltfLargView(data,fileList) {
    let storeFlag = true
    const baseFilename = fileList.filepath.substring(fileList.filepath.lastIndexOf('/') + 1, fileList.filepath.lastIndexOf('.'));

    alert(baseFilename)

    const modalRef = this.modal.open(CaraosalPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.storeSelectedGLTFFile = data;
    modalRef.componentInstance.GarmentSelectedFlag = storeFlag;
    modalRef.componentInstance.clothId = fileList.link;
    modalRef.componentInstance.fileClothName = baseFilename;
    modalRef.componentInstance.id = fileList.id;




    modalRef.result.then((result) => {
    }).catch((error) => {

    });
  }

  async generateAndUploadImage(ref: any, fileNameWithoutExtension: string) {
    await new Promise(resolve => setTimeout(resolve, 5000));
    const modelViewer = document.getElementById("viewer") as ModelViewerElement;
    if (modelViewer) {
        const blob = await modelViewer.toBlob({ idealAspect: false });
        const url = URL.createObjectURL(blob);
  
        // Add a 2-second delay
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        // Create a reference for the mini image
        const miniImagePath = `Uploads/${this.clothId}/${this.clothDetailsList.Name}_mini.png`;
        const miniImageRef = this.storage.ref(miniImagePath);
        
        // Upload the mini image
        await miniImageRef.put(blob);
        const miniImageUrl = await miniImageRef.getDownloadURL().toPromise();
        
        URL.revokeObjectURL(url);
        
        return miniImageUrl;
    } else {
        console.error('ModelViewer element not found');
        return '';
    }
  }




  /// new modifided code structure for the edit 

  onScroll() {
    this['height'] = this.BrandStoriyScroll.nativeElement.scrollTop
  }
  
  
onMouseDown(event: MouseEvent): void {
  this.isMouseDown = true;
  this.startX = event.pageX - this.brandStoriyScrollA.nativeElement.offsetLeft;
  this.scrollLeft = this.brandStoriyScrollA.nativeElement.scrollLeft;
}

onMouseLeave(): void {
  this.isMouseDown = false;
}

onMouseUp(): void {
  this.isMouseDown = false;
}

onMouseMove(event: MouseEvent): void {
  if (!this.isMouseDown) return;
  event.preventDefault();
  const x = event.pageX - this.brandStoriyScrollA.nativeElement.offsetLeft;
  const walk = (x - this.startX) * 1.5; // Scroll faster by multiplying by 2
  this.brandStoriyScrollA.nativeElement.scrollLeft = this.scrollLeft - walk;
}

scrollsLeft(): void {
  this.brandStoriyScrollA.nativeElement.scrollBy({
    left: -200, // Adjust the scroll distance as needed
    behavior: 'smooth'
  });
}

scrollRight(): void {
  this.brandStoriyScrollA.nativeElement.scrollBy({
    left: 200, // Adjust the scroll distance as needed
    behavior: 'smooth'
  });
}



selectByScroll() {
  this.byScroll = false
}



selectBrand(brand) {
this.selectedBrand = brand
this.selectedBrandId = brand.id
this.brandNameSelected = brand.BrandName
this.scrollFlag = false


  console.log("brand",brand)
let gender = "g"
let tab = brand.Gender == 'M' ? 'mens-wear' : brand.Gender == 'F' ? 'womens-wear' : 'story'
this.setTab(tab, gender)
setTimeout(() => {
  let brandId = brand.id
  this.commonService.scrollToElementByIdbrand(brandId, this.brandStoriyScrollA);
  // Adjust the scroll position by -60px after the service call
  const element = document.getElementById(brandId);
  if (element) {
    element.scrollLeft -= 60;
  }
}, 50); // Adjust the timeout duration as needed (500ms in this example)
setTimeout(() => {
  this.byScroll = false
}, 200)
}




private setupInfiniteScroll(): void {
  this.slider = this.brandStoriyScrollA.nativeElement;

  const moveElement = () => {
    // Use requestAnimationFrame for smoother visual updates
    requestAnimationFrame(() => {
      const firstChild = this.slider.firstElementChild as HTMLElement;
      const lastChild = this.slider.lastElementChild as HTMLElement;
      // Calculate the buffer based on the child width
      const childWidth = firstChild.offsetWidth;
      const buffer = childWidth * 2; // Adjust the buffer size as needed
      if (this.slider.scrollLeft + buffer >= this.slider.scrollWidth - this.slider.offsetWidth) {
        // Move the first child to the end and adjust scrollLeft
        this.slider.appendChild(firstChild);
        this.slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
      } else if (this.slider.scrollLeft <= buffer) {
        // Move the last child to the beginning and adjust scrollLeft
        this.slider.insertBefore(lastChild, firstChild);
        this.slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
      }
    });
  };

  // Throttle scroll events
  let isScrolling: any;
  this.scrollEventListener = () => {
    window.clearTimeout(isScrolling);
    isScrolling = setTimeout(moveElement, 0); // Increase the timeout to delay the element movement
  };

  // Add smooth scrolling CSS transition if not already present
  this.slider.style.transition = 'scroll-left 0.1s ease-out';
  this.slider.addEventListener('scroll', this.scrollEventListener);
}

ngOnDestroy() {
  // Clean up the scroll event listener to prevent memory leaks
  if (this.slider && this.scrollEventListener) {
    this.slider.removeEventListener('scroll', this.scrollEventListener);
  }
}


setTab(tab, gender){
  this.tab = tab
  if (tab == 'womens-wear' || tab == 'mens-wear') {
    this.packJsonObject = []
    // this.activeDownloads.clear();
    this.flipClose()
    this.showBrandPackDetails()
  }
}



/// zip unzip process

async showBrandPackDetails() {
  const newGender = this.tab == 'mens-wear' ? 'M' : this.tab == 'womens-wear' ? 'F' : "";
  this.zipList = {};
  this.showTheBrandMode = true
  this.selectedGender = newGender;
  this.errMessage = "";
  this.isloading = true;
  this.isLoadingStyle = true;
  this.downloadPack0();
}
async downloadPack0() {
  console.log('pack0 loading intilze...')
  this.isloading = true;
  this.isLoadingStyle = true;
  this.testVar = 'Change';
  let brandId = this.selectedBrandId;
  let zipfilename = this.pack0;  // Assuming this is the zip file name
  let gender = this.selectedGender;
  let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + zipfilename;
  let packlocationTemp = "AvatarBlenderClothes/" + brandId + "_" + gender + '/pack0';
  console.log("packlocation", packlocation)
  let brandEncodedurl = packlocation;
  this.callDownloadcheck = brandId + gender
  let uniqueKey = packlocation;
  this.callDownloadcache = this.callDownloadcheck
  if (!brandId || !gender || !zipfilename) {
    this.isloading = false;
    this.isLoadingStyle = false;
    this.downloadFlag = false;  // Reset the flag
    return;
  }
  let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4';
  this.packUrl = url
  try {
    // const res = await this._httpClient.get(url, { observe: 'response', responseType: 'blob' }).toPromise();
    // await this.processZipFile(res.body, this.processResponse.bind(this));
    let newPacketData = await this.downloadprocessPackZip(packlocationTemp)
    this.extractFileData(newPacketData)
  } catch (error) {
    this.handleErrors(error);
  } finally {
    this.isloading = false;
    this.isLoadingStyle = false;
    this.downloadFlag = false;  // Reset the flag after download is complete or fails
  }
  console.log('pack0 loaded........')
}
handleErrors(error) {
  console.log('error', error)
  this.packJsonObject = [];
  this.isloading = false;
  this.errMessage = "Pack0 is not found for this selection !!!"
  this.isLoading = false;
  this.isLoadingStyle = false;
}
async downloadprocessPackZip(url: string) {
  try {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/downloadPack0";
    const response = await this.http.get<any>(functionUrl, { params: { packlocation: url } }).toPromise();
    console.log('Zip response:', response);
    return response;
  } catch (error) {
    console.error('Error processing cloth zip:', error);
    throw error;
  }
}
async extractFileData(result) {
  const packJsonObject = result.packJsonObject;
  let data = result.zipList;
  this.newZipLists = data;

  for (const key in this.newZipLists) {
    if (this.newZipLists.hasOwnProperty(key)) {
      const base64String = this.newZipLists[key];
      const blobUrl = await this.base64ToBlobUrl(base64String);
      this.newZipLists[key] = blobUrl; // Assuming you sanitize in base64ToBlobUrl method
    }
  }
  this.zipList = this.newZipLists;
  console.log("Updated newZipLists:", this.zipList);

  // Refactor using loop for categories
  ['hFiles', 'uFiles', 'bFiles', 'fFiles'].forEach((category, index) => {
    this.extractFiles(packJsonObject, category, index);
  });
}
extractFiles(packJsonObject: any, category: string, index: number) {
  const filesArray: string[] = packJsonObject.brandSaves[category];
  const nonEmptyFilesArray: string[] = filesArray.filter((file: string) => file !== '');
  let tcount = packJsonObject.brandSaves['totalCount']
  let newPackJsonObject: any = {
    value: {
      files: nonEmptyFilesArray,
      count: tcount
    }
  };
  this.packJsonObject[index] = newPackJsonObject; // Assign to the appropriate index
  console.log(`Updated files for ${category}`, this.packJsonObject);
}
async base64ToBlobUrl(base64String: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    const blobUrl = URL.createObjectURL(blob);
    resolve(blobUrl);
  });
}
async extractNextFileData(result) {
  const packJsonObject = result;
  let nextPackList = packJsonObject.imageUrls;
  let newListObject = {};

  for (const key in nextPackList) {
    if (nextPackList.hasOwnProperty(key)) {
      const base64String = nextPackList[key];
      const blobUrl = await this.base64ToBlobUrl(base64String);
      // Remove file extension from key
      const keyWithoutExtension = key.replace(/\.[^.]+$/, '');
      newListObject[keyWithoutExtension] = blobUrl; // Assuming you sanitize in base64ToBlobUrl method
    }
  }

}


scrollAndUnZipFileIntilize(containerType) {
  let garmentType;
  let typeNo;
  let scrollContainer: ElementRef;

  if (this.tab === 'womens-wear') {
    switch (containerType) {
      case 'headgear':
        scrollContainer = this.scrollContainerWHeadGear;
        garmentType = containerType;
        typeNo = 0;
        break;
      case 'upperwear':
        scrollContainer = this.scrollContainerWUpper;
        garmentType = containerType;
        typeNo = 1;
        break;
      case 'bottomwear':
        scrollContainer = this.scrollContainerWBottom;
        garmentType = containerType;
        typeNo = 2;
        break;
      case 'footwear':
        scrollContainer = this.scrollContainerWFootwear;
        garmentType = containerType;
        typeNo = 3;
        break;
    }
  } else {
    switch (containerType) {
      case 'headgear':
        scrollContainer = this.scrollContainerMHeadGear;
        garmentType = containerType;
        typeNo = 0;
        break;
      case 'upperwear':
        scrollContainer = this.scrollContainerMUpper;
        garmentType = containerType;
        typeNo = 1;
        break;
      case 'bottomwear':
        scrollContainer = this.scrollContainerMBottom;
        garmentType = containerType;
        typeNo = 2;
        break;
      case 'footwear':
        scrollContainer = this.scrollContainerMFootwear;
        garmentType = containerType;
        typeNo = 3;
        break;
    }
  }
  let count = this.packJsonObject[typeNo].value['count'];
  window.clearTimeout(0);
  if (count >= 25) {
    // Only start scrolling if the count is greater than or equal to 25
    setTimeout(() => {
      // this.scrollingContainer(scrollContainer, garmentType, typeNo, this.tab);
    }, 100);
  }
  setTimeout(() => {
    // this.setupInfiniteScroll(typeNo);
  }, 100);
}

async open3Dflag(garmetBinaryImg, garmentType: any, garmentId, typeWear) {
  // await this.subscribeToUserFilesList()
  this.source = ''
  this.flipFlag = true
  this.garmentImgage = garmetBinaryImg
  this.garmentClothType = garmentType
  this.garmentClothId = garmentId
  this.typeWear = typeWear
  this.clothDetails = this.garmentsCollection.find((b: any) => b.id == garmentId);
  this.selectIcon('popup')
  this.loadGarmentData(garmentId)
}

selectIcon(icon) {
  this.selectedIcon = ''
  this.selectedIcon = icon
  this.iconselctFlag = true
}

// async loadGarmentData(garmentId) {
//   this.isloadingPanel = true
//   this.garmentData = []
//   this.userFileList = []
//   this.userFiledetails3d = []
//   this.userSnapShotListGarment = []
//   const startTime = performance.now();
//   console.log(`Data fetching started at: ${startTime} ms`);
//   this.garmentData = await this.garmentPanelDataFunction(garmentId);
//   this.isloadingPanel = false
//   this.clothDetails = this.garmentData.garment;
//   const endTime = performance.now();
//   console.log(`Data fetching ended at: ${endTime} ms`);
//   const duration = endTime - startTime;
//   console.log(`Data fetching duration MicroUrl: ${duration} ms`);
//   let filePathNewTemp = this.garmentData.userFiles.find((file) => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
//   this.source = filePathNewTemp ? filePathNewTemp.fileurl : null;
//   this.brandObject = this.garmentData.brand;
//   // this.branUrl = this.garmentData.brandLogoUrl;
//   this.userSnapShotListGarment = this.garmentData.snapshots.object;

//   let userFiles = this.garmentData.userFiles;
//   this.filteredData = userFiles
//   const storageRef = this.storage.ref(filePathNewTemp.filepath);
//   const url = await storageRef.getDownloadURL().pipe(finalize(() => { })).toPromise();
//   console.log('Download URL:', url);
//   this.source = url
//   console.log("this.source", this.source);
//   this.tab = this.clothDetails.Gender === 'M' ? 'mens-wear' : 'womens-wear';
//   let filelist2d = this.filteredData.filter(
//     (uploadlist) =>
//       uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload2D'
//   );
//   let filelist3d = this.garmentData.userFiles.filter(
//     (uploadlist) =>
//       uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload3D'
//   );

//   this.userFileList = filelist2d;
//   console.log('filelist2d', filelist2d)
//   this.userFiledetails3d = filelist3d;
//   console.log('filelist3d', filelist3d)
//   setTimeout(() => {
//     // this.selectGarmentImage(this.selectedImageIndex, this.StoryId);
//   }, 500);

//   // ource URL is null or undefined, update it
//   // this.checkUrl(this.source)

// }



async loadGarmentData(garmentId) {
  this.isloadingPanel = true;
  this.garmentData = [];
  this.userFileList = [];
  this.userFiledetails3d = [];
  this.userSnapShotListGarment = [];
  const startTime = performance.now();
  console.log(`Data fetching started at: ${startTime} ms`);

  try {
    this.garmentData = await this.garmentPanelDataFunction(garmentId);
    this.isloadingPanel = false;
    this.clothDetails = this.garmentData.garment;

    const endTime = performance.now();
    console.log(`Data fetching ended at: ${endTime} ms`);
    const duration = endTime - startTime;
    console.log(`Data fetching duration MicroUrl: ${duration} ms`);

    let filePathNewTemp = this.garmentData.userFiles.find(
      (file) => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache'
    );

    // Check if filePathNewTemp exists before accessing its properties
    if (filePathNewTemp) {
      this.source = filePathNewTemp.fileurl;

      // Assuming you're using Firebase, handle the storage reference safely
      const storageRef = this.storage.ref(filePathNewTemp.filepath);
      try {
        const url = await storageRef.getDownloadURL().pipe(finalize(() => {})).toPromise();
        console.log('Download URL:', url);
        this.source = url;
      } catch (error) {
        console.error('Error fetching download URL:', error);
        this.fileUrlUpadate(this.clothDetails)
      }
    } else {
      console.warn('No matching file found for Garment360Cache.');
      this.fileUrlUpadate(this.clothDetails)
    }

    this.brandObject = this.garmentData.brand;
    this.userSnapShotListGarment = this.garmentData.snapshots.object;

    let userFiles = this.garmentData.userFiles;
    this.filteredData = userFiles;

    this.tab = this.clothDetails.Gender === 'M' ? 'mens-wear' : 'womens-wear';

    // Filter files for 2D and 3D uploads
    let filelist2d = this.filteredData.filter(
      (uploadlist) => uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload2D'
    );
    let filelist3d = this.garmentData.userFiles.filter(
      (uploadlist) => uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload3D'
    );

    this.userFileList = filelist2d;
    this.userFiledetails3d = filelist3d;

    console.log('filelist2d', filelist2d);
    console.log('filelist3d', filelist3d);

    // Delay before selecting garment image
    setTimeout(() => {
      // this.selectGarmentImage(this.selectedImageIndex, this.StoryId);
    }, 500);

  } catch (error) {
    console.error('Error loading garment data:', error);
    this.isloadingPanel = false;
  }
}

// async garmentPanelDataFunction(garmentId) {
//   let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";
//   return this.http.post<any>(functionUrl, { garmentId: garmentId, fileType: this.typeWear }).toPromise();
// }


async garmentPanelDataFunction(garmentId) {
  let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";

  // Log garmentId and fileType before making the request
  console.log("garmentId:", garmentId, "fileType:", this.typeWear);

  return this.http.post<any>(functionUrl, { garmentId: garmentId, fileType: this.typeWear }).toPromise();
}


DeatailsPopup(garmetBinaryImg, garmentType: any, object, garmentTypeForPopup, brandflag) {
}



switch3DImage() {
  this.swichImageFlage = !this.swichImageFlage
}

setSelectedGarmetCloth(event) {
  if (event == 'Cloth') {
    // this.setCloth()
  }
}

flipClose() {
  this.flipFlag = false
  this.garmentScrollFlag = false
  this.swichImageFlage = true
  this.userFileList = []
  this.userFiledetails3d = []
  this.userSnapShotListGarment = []
  this.garmentData = []
  this.clothDetails = {}
}


suggest($event) {
  this.scrollFlag = true;
  this.byScroll = true;
  console.log("byScroll 7", this.byScroll)
  let inputValue = $event.target.value.toLowerCase();
  const startsWithMatches = this.brandList.filter(brand =>
    brand.BrandName && brand.BrandName.toLowerCase().startsWith(inputValue)
  );

  const includesMatches = this.brandList.filter(brand =>
    brand.BrandName && brand.BrandName.toLowerCase().includes(inputValue)
  ).filter(brand => !startsWithMatches.includes(brand));
  this.filterBrandListNew = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));
}

blankplaceHolder() {
  this.brandNameSelected = ''
}

async fileUrlUpadate(data) {
  console.log(data)
  let garment = data
  let zipNmae = 'pack0';
  let packlocation =
    'AvatarBlenderClothes%2F' +
    garment.BrandID +
    '_' +
    garment.Gender +
    '%2F' +
    zipNmae;
  let garmentLink =
    'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
    packlocation +
    '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
  const url = garmentLink;
  const cloth = garment.id;
  console.log("url..............", url)

  await this.commonService.downloadAndProcessPack(url, cloth).subscribe({
    next: (response) => console.log('Success:', response),
    error: (error) => console.error('Error:', error)
  });

}
}

