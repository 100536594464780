<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="brand-profile-color">
    <!-- <button class="scroll-button left" (click)="scrollsLeft()">
        &#8249;
    </button> -->
    <div #brandStoriyScrollA [ngClass]="scrollFlag ? 'd-none' : ''" class="slider-css" (scroll)="selectByScroll()">
        <div class="" *ngFor="let brand of brandList" [ngClass]="byScroll != true   ? 'prt-Evt' :''">
            <div *ngIf="selectedBrand == brand  ">
                <div
                    [ngClass]="selectedBrand == brand  ? 'shadow-brand-slide' : (selectedBrand != undefined && !byScroll ? 'd-none' :'')">
                    <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                    </div>
                </div>
            </div>
            <img *ngIf="brand?.Brandurl != undefined" [id]="brand.id" class="m-1"
                style="height:60px !important; width:80px !important;"
                [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                (click)="selectBrand(brand)" [src]="brand.Brandurl" />
        </div>
        <!-- <button class="scroll-button right" (click)="scrollRight()">
            &#8250;
        </button> -->
    </div>

    <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
        <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
            <img [id]="brand.id" class="m-1" style="height:60px !important; width:80px !important; background: white;"
                [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                (click)="selectBrand(brand)" [src]="brand.Brandurl" />
        </div>
    </div>

    <div class="input-wrapper" *ngIf="brandList">
        <input [disabled]="exclosiveMode == 'Y'" id="BrandName" type="text" class="form-control custom-input no-rounded"
            (input)="suggest($event)" (click)="blankplaceHolder()">
        <label for="BrandName" class="placeholder-start text-white">{{brandNameSelected}}</label>
    </div>
</div>


<div class="image-slider" *ngIf="this.selectedBrandId && !isShowBrandSpaces " (scroll)="scrollEven()">
    <div class="image-slider-inner" *ngFor="let even of evenfileList">
        <img class="image-slide" (click)="selectEven(even)" [src]="even.evnCompressUrl" />
    </div>
</div>

<div class="image-slider" *ngIf="this.selectedBrandId &&  isShowBrandSpaces" (scroll)="scrollEven()">
    <div class="image-slider-inner" *ngFor="let even of filteredEvenfileList">
        <img class="image-slide" (click)="selectEven(even)" [src]="even.evnCompressUrl" />
    </div>
    <div class="image-slider-inner">
        <div class="image-slide blank-slide" (click)="addNew() ">
            <span class="plus-icon" >+</span>
        </div>
    </div>
</div>




<div class="col-lg-12 justify-content-center text-center scroll">
    <div class="fixed-container" *ngIf="this.selectedEvenObj">
        <div class="justify-content-center text-center  brand-profile-sy">
            <div id="mySidenav" class="sidenav" [style.height.px]="windowHeight - 150">
                <div class="image-slider m-0">
                    <img height="100px" [src]="selectedEvenObj.evnCompressUrl" />
                </div>
                <div class="save-evn m-0">{{selectedEvenObj.displayName}}</div>
                <button class="btn save-evn" *ngIf="!isShowBrandSpaces" (click)="saveBrandSpace()">Save</button>
                <button class="btn save-evn" *ngIf="isShowBrandSpaces"  (click)="deleteBrandSpace()">delete</button>

                <!-- BrandSpaces -->


                <model-viewer *ngIf="selectedevenUrl" id="poster" camera-controls touch-action="pan-y"
                    data-js-focus-visible="false" [attr.skybox-image]="selectedevenUrl"
                    alt="A 3D model of a sphere reflecting a sunrise" [attr.src]="gltfImgSrc">
                </model-viewer>


                <div class="div-center class-desktop" *ngIf="!snaploader">
                    <div class="row w-100 image-margin"
                        [ngStyle]="evnSnapList.length < 12 ? {} : { 'height.px': windowHeight - 180}">
                        <div class="col-6 p-0" *ngFor="let snapshot of evnSnapList; let i=index;"
                            [ngClass]="{'d-none': snapshot.SnapshotFileUrl == ''}">
                            <div class="d-flex align-items-center justify-content-center">
                                <img class="shadow-1-strong rounded m-1 story-img" [src]="snapshot.SnapshotFileUrlMini">
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="snaploader" class="text-center">
                    <div class="spinner-border m-2" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedevenUrl != undefined && !mainMenuFlag">
    <app-news3-d-popup [EnvUrl]="this.selectedevenUrl" [FunctionFlag]="this.functionFlag">
    </app-news3-d-popup>
</div>